import { DownOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-scroll";

const ExploreMore = () => {
	return (
		<>
			<div className=" flex justiy-center items-center w-full bg-white" id="explore">
				<Link
					className=" mx-auto mt-2 border-none bg-white cursor-pointer flex flex-col justify-center items-center"
					to="products"
					spy={true}
					smooth={true}
					offset={-40}
					duration={500}
				>
					<DownOutlined className="" />
					<h1 className=" sm:text-xl text-[18px] text-[#535353] font-medium">Explore more </h1>
				</Link>
			</div>
		</>
	);
};

export default ExploreMore;
