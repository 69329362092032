import { LeftOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { cardImage } from "../../constants/defensysStatic";

const ProductsCard = () => {
  const [showCard, setShowCard] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);

  const handleCardClick = (index) => {
    setSelectedCardIndex(index);
    setShowCard(true);
  };

  const handleBackButtonClick = () => {
    setShowCard(false);
  };
  const [isXlScreen, setIsXlScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsXlScreen(window.innerWidth >= 1280);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // console.log(isXlScreen);
  // console.log(selectedCardIndex);
  const textAnimate = {
    offscreen: { y: 150, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 0.7 },
    },
  };
  const backAnimate = {
    offscreen: { y: 20, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 0.7 },
    },
  };
  const imgAnimate = {
    offscreen: { y: -150, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.3, duration: 0.7 },
    },
  };
  return (
    <div>
      <div id="productInfo">
        {!showCard && (
          <div className=" grid lg:grid-cols-10 sm:grid-cols-4 grid-cols-2 gap-3 px-4">
            {cardImage.map((card, index) => (
              <Link
                to={isXlScreen ? "defensys" : "productInfo"}
                spy={true}
                smooth={true}
                offset={isXlScreen ? -40 : -80}
                duration={0}
                className="col-span-2 md:h-[550px] h-[400px] relative cursor-pointer hover:opacity-100 shadow-xl"
                key={index}
                onClick={() => handleCardClick(index)}

                // onClick={() => handleCardClick(index)}
              >
                <img className=" w-full h-full brightness-75 hover:brightness-100 " src={card.imgSrc} alt={card.alt2} />
                <div className=" absolute bottom-0 top-0 right-0 left-0 max-w-[200px] max-h-[200px] m-auto bg-white p-5 rounded-full shadow-lg xl:hover:scale-110 lg:hover:scale-90 md:hover:scale-110 sm:hover:scale-[.85] hover:scale-90 xl:scale-100 lg:scale-[.85] md:scale-100 sm:scale-75 scale-[.85] hover:opacity-100 sm:opacity-90 opacity-100">
                  <img
                    className="absolute bottom-0 top-0 left-0 right-0 max-w-[150px] m-auto z-20"
                    src={card.logo}
                    // alt={`${card.titleTop} +${card.titleBot}`}
                    alt={card.alt}
                  />
                </div>
                <div className="absolute bottom-0 pl-7 pb-5">
                  <h2 className=" text-white italic font-bold text-[20px] leading-[30px]">{card.titleTop}</h2>
                  <h2 className=" text-white italic font-bold text-[20px] leading-[30px]">{card.titleBot}</h2>
                </div>
              </Link>
            ))}
          </div>
        )}

        {showCard && (
          <motion.div
            className=" grid grid-cols-4 gap-x-4 gap-y-10"
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true, amount: 0.3 }}
            transition={{ staggerChildren: 0.2 }}
          >
            <motion.div className=" col-span-4 sm:pb-5 pb-0" variants={backAnimate}>
              <Link to="defensys" spy={true} smooth={true} offset={-40} duration={0} className="lg:block hidden">
                <span className=" cursor-pointer text-xl" onClick={handleBackButtonClick}>
                  <LeftOutlined /> Back
                </span>
              </Link>
              <span className=" cursor-pointer text-xl lg:hidden block" onClick={handleBackButtonClick}>
                <LeftOutlined /> Back
              </span>
            </motion.div>
            <div className=" xl:col-span-2 col-span-4 xl:order-2 order-last">
              <motion.div
                className=" flex flex-col sm:gap-y-5 gap-y-1 sm:pr-28 pr-0 sm:pl-5 pl-0 "
                variants={textAnimate}
              >
                <h2 className=" font-semibold sm:text-[32px] text-[24px] leading-[48px]">
                  {cardImage[selectedCardIndex]?.cardContent[0]?.title}
                </h2>
                <h2 className=" font-medium sm:text-[20px] text-[18px] leading-[30px]">
                  {cardImage[selectedCardIndex]?.cardContent[0]?.sub}
                </h2>
                <h2 className=" sm:text-[18px] text-[16px] leading-[27px] text-justify">
                  {cardImage[selectedCardIndex]?.cardContent[0]?.details}
                </h2>
                <ul className=" ml-8 sm:text-[18px] text-[16px] leading-[27px]">
                  {cardImage[selectedCardIndex]?.cardContent[0]?.list.map((item, index) => (
                    <li key={index}>
                      <p className=" m-0 text-[18px] leading-[27px]">{item}</p>
                    </li>
                  ))}
                </ul>
                {/* <h2 className=" font-medium sm:text-[20px] text-[18px] leading-[30px]">
									{cardImage[selectedCardIndex]?.cardContent[0]?.sub2}
								</h2>
								<h2 className=" sm:text-[18px] text-[16px] leading-[27px] text-justify">
									{cardImage[selectedCardIndex]?.cardContent[0]?.details2}
								</h2> */}
                {selectedCardIndex === 0 && (
                  <>
                    <h2 className=" font-medium sm:text-[20px] text-[18px] leading-[30px]">
                      {/* Drone Monitoring */}
                      {cardImage[selectedCardIndex]?.cardContent[0]?.sub2}
                    </h2>
                    <h2 className=" sm:text-[18px] text-[16px] leading-[27px] text-justify">
                      {/* Aerial surveillance of situations or incidents viewable to the command center
											video walls or executive mobile apps. */}
                      {cardImage[selectedCardIndex]?.cardContent[0]?.details2}
                    </h2>
                  </>
                )}
                <Link to="contact" spy={true} smooth={true} offset={-40} duration={700}>
                  <button className=" sm:px-20 px-14 sm:py-6 py-4 rounded-md bg-defblue hover:bg-headerblue text-white border-none sm:text-[18px] text-[16px] font-semibold cursor-pointer">
                    Request for Quotation
                  </button>
                </Link>
              </motion.div>
              {/* <ul className=" ml-8 text-[18px] leading-[27px]">
							{cardImage[selectedCardIndex]?.cardContent[0]?.list.map((item, index) => (
								<li key={index}>
									<p className=" m-0 text-[18px] leading-[27px]">{item}</p>
								</li>
							))}
						</ul> */}
            </div>
            <motion.div className=" xl:col-span-1 col-span-2 flex xl:justify-end justify-center" variants={imgAnimate}>
              <div className=" sm:min-w-[275px] min-w-[100px] h-full">
                <img className=" w-full h-full" src={cardImage[selectedCardIndex]?.cardContent[0]?.img1} alt="/" />
              </div>
            </motion.div>
            <motion.div className=" xl:col-span-1 col-span-2 flex justify-center" variants={imgAnimate}>
              <div className=" sm:min-w-[275px] min-w-[100px] h-full">
                <img className=" w-full h-full" src={cardImage[selectedCardIndex]?.cardContent[0]?.img2} alt="/" />
              </div>
            </motion.div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default ProductsCard;
