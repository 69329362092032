import { ConfigProvider, Layout } from "antd";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../../components/ContentWriter/Navbar";
import Sidebar from "../../../components/ContentWriter/Sidebar";

const PageLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { Content } = Layout;
  return (
    <Layout
      style={{
        maxHeight: "100vh",
      }}
    >
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout>
        <Navbar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content>
          <div className="h-full overflow-auto">
            <ConfigProvider theme={{ token: { colorPrimary: "#1C4E84" } }}>
              <Outlet />
            </ConfigProvider>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default PageLayout;
