import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Form, Input, Spin } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defensyslogo } from '../../assets/logo/index.js';
import { loggingIn } from '../../store/slices/auth/authSlice.js';

const Login = () => {
	const isLoading = useSelector((state) => state.auth.isLoading);

	const dispatch = useDispatch();
	const registerHandle = (values) => {
		dispatch(loggingIn(values));
		// console.log(test);
	};
	// console.log(process.env.REACT_APP_BASE_URL);

	return (
		<div className=" h-[100vh] flex justify-center items-center bg-[#003664]">
			<Spin spinning={isLoading}>
				<Form
					onFinish={registerHandle}
					className="sm:min-w-[450px] min-w-[300px] max-w-[700px] mx-auto px-10 py-10 rounded-xl shadow-md bg-white"
				>
					<div className=" flex flex-col justify-center items-center">
						<img className=" sm:w-[200px] w-[150px] py-5" src={defensyslogo} alt="/" />
						<p className=" m-0 font-semibold text-lg py-5">Login to your Account</p>
					</div>

					<Form.Item
						// label="Email"
						name="email"
						// initialValue={'kim@defensys.ph'}
						rules={[
							{
								required: true,
								message: 'Please input your email!',
							},
						]}
					>
						<Input prefix={<MailOutlined className="pr-1" />} placeholder="Email" size="large" />
					</Form.Item>
					<Form.Item
						// label="Password"
						name="password"
						// initialValue={'Defensys2023'}
						rules={[
							{
								required: true,
								message: 'Please input your password!',
							},
						]}
					>
						<Input.Password
							prefix={<LockOutlined className="pr-1" />}
							placeholder="Password"
							size="large"
						/>
					</Form.Item>
					<Form.Item>
						<div className=" flex justify-center items-center sm:pt-5 pt-3">
							<button className=" px-20 sm:py-3 py-2 sm:text-lg text-base border-none rounded-md hover:bg-[#1e4694] bg-[#003664]  text-white cursor-pointer">
								Sign In
							</button>
						</div>
					</Form.Item>
				</Form>
			</Spin>
		</div>
	);
};

export default Login;
