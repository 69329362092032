import { motion } from "framer-motion";
import React from "react";
import { defensyslandscape } from "../assets/images";
import ProductsCard from "./Products/ProductsCard";

const Products = () => {
  const textAnimate = {
    offscreen: { y: 0, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 0.7 },
    },
  };
  const cardAnimate = {
    offscreen: { y: 100, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.3, duration: 0.7 },
    },
  };
  return (
    <motion.div
      id="products"
      className="  min-h-screen container mx-auto sm:pb-32 pb-10 px-4"
      initial={"offscreen"}
      whileInView={"onscreen"}
      viewport={{ once: true, amount: 0.1 }}
      transition={{ staggerChildren: 0.2 }}
    >
      {/* <div> */}
      <div className=" flex justify-center items-center max-w-[926px] mx-auto py-10">
        <img className="w-full h-full" src={defensyslandscape} alt="Command Center Solution" />
      </div>
      {/* </div> */}
      <div className=" grid grid-cols-7 sm:pt-16 sm:pb-16 pt-5 pb-10" id="defensys">
        <motion.div className=" md:col-span-3 col-span-7" variants={textAnimate}>
          <h1 className=" m-0 sm:text-[48px] text-[28px] font-semibold sm:leading-[65px] leading-[50px] text-[#534E4E]">
            Command Center
          </h1>
          <h1 className=" m-0 sm:text-[48px] text-[28px] font-semibold sm:leading-[65px] leading-[50px] text-defblue">
            Solution
          </h1>
        </motion.div>
        <motion.div className=" md:col-span-4 col-span-7" variants={textAnimate}>
          <p className=" text-[#545454] sm:text-[18px] text-[16px] leading-[32px] text-justify font-normal">
            A Disaster Risk Reduction Management (DRRM) platform that centrally monitors weather, earthquakes,
            emergencies, and video surveillance for synchronized operations and provisions of reports and responses as
            needed. The Defensys Community is the mobile app that provides tools and live reports of potential hazards,
            gearing towards Situational Awareness For Everyone (SAFE).
          </p>
        </motion.div>
      </div>
      <motion.div variants={cardAnimate}>
        <ProductsCard />
      </motion.div>
    </motion.div>
  );
};

export default Products;
