import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getNewsFetch } from "../../store/slices/news/newsSlice";

const NewsCardFetch = () => {
  // const photos = useSelector((state) => state.photos.photosList);
  const news = useSelector((state) => state.news.newsList);
  const sortedNews = [...news]?.sort((a, b) => new Date(b.subtitle) - new Date(a.subtitle));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNewsFetch());
  }, []);
  // console.log('NEW', news);
  return (
    <div className=" grid lg:grid-cols-8 sm:grid-cols-4 grid-cols-2 gap-10">
      {/* {news.slice(0, 4).map((news, i) => { */}

      {sortedNews?.slice(0, 4).map((news, i) => {
        // console.log(`${process.env.REACT_APP_BASE_URL}/${news.coverUrl}`);
        const date = moment(news?.subtitle).format("LL");

        const textContent =
          news?.contents
            .filter((i) => i.type === "text")
            .map((i) => i.value)
            .join(" ")
            .slice(0, 120) + "...";
        // console.log(textContent);

        // const details = textContent?.slice(0, 120) + '...';
        // console.log(details);
        return (
          <Link
            key={i}
            to={`/news/${news.id}`}
            onClick={() => window.scrollTo(0, 0)}
            className="col-span-2 bg-gray-50 shadow-xl rounded-lg no-underline hover:scale-105"
          >
            {/* <div className=" col-span-2 bg-gray-50 shadow-xl rounded-lg" key={i}> */}
            <div className=" p-2 w-full">
              <img
                className=" w-full sm:max-h-[200px] h-[300px]"
                src={`${process.env.REACT_APP_BASE_URL}/${news.coverUrl}`}
                alt={news.headline}
              />
            </div>
            <div className=" px-4 pt-5 pb-10">
              <h1 className=" text-defblue text-[20px] leading-[30px] font-semibold m-0">{news.headline}</h1>
              {/* <h1 className=" text-defblue text-[20px] leading-[30px] font-semibold m-0">
								{news.subtitle}
							</h1> */}
              <p className=" text-[#4A4A4A] text-[14px] leading-[21px] font-medium my-5">
                {date}
                {/* {news.subtitle} */}
              </p>
              <p className=" text-[#4A4A4A] text-[14px] leading-[21px] font-medium">{textContent}</p>
            </div>
            {/* </div> */}
          </Link>
        );
      })}
    </div>
  );
};

export default NewsCardFetch;
