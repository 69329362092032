import { call, put, takeLatest } from 'redux-saga/effects';
import { login } from '../../api/authApi/authApi';
import * as authSlice from '../../slices/auth/authSlice.js';

function* loginRequest({ payload }) {
	const { loginSuccess, loginError } = authSlice;
	try {
		const { data } = yield call(login, payload);
		if (data.name === 'AxiosError') {
			yield put(loginError({ message: data.message }));
		} else {
			yield put(loginSuccess(data));
			setTimeout(() => {
				window.location.reload();
			}, 300);
		}
	} catch (error) {
		yield put(loginError({ message: 'Login Failed' }));
	}
}

export default function* authSaga() {
	yield takeLatest(authSlice.loggingIn.type, loginRequest);
}
