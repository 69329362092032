import { Button, Checkbox, Col, ConfigProvider, Form, Input, InputNumber, Row } from 'antd';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { regExp_MobileNumber, regExp_Names } from '../../helpers/regex';
import { request } from '../../store/slices/reqQuotation/reqQuotationSlice';

const ContactForm = () => {
	const dispatch = useDispatch();
	const { isLoading } = useSelector((state) => state.reqQuotation);
	// const onFinish = (values) => {
	// 	values.productInquiry = values.productInquiry.join(', ');
	// 	dispatch(request(values));
	// };
	const [form] = Form.useForm();
	const onFinish = async (values) => {
		// try {
		// 	values.productInquiry = values.productInquiry.join(', ');

		// 	// Make API call using Axios
		// 	const response = await axios.post(
		// 		'https://tracesos.taguig.gov.ph:9008/api/web/public/sendContactForm',
		// 		values
		// 	); // Replace 'API_URL' with your actual API URL

		// 	// Dispatch success action with response data
		// 	dispatch(requestSuccess(response.data));
		// } catch (error) {
		// 	// Dispatch error action with error message
		// 	dispatch(requestError({ message: error.message }));
		// }
		dispatch(
			request({
				body: values,
				cb: () => {
					form.resetFields();
				},
			})
		);
	};

	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const mobileNumberRef = useRef(null);
	const emailAddressRef = useRef(null);
	const productInquiry = useRef(null);
	const messageRef = useRef(null);

	const fieldRefs = {
		firstName: firstNameRef,
		lastName: lastNameRef,
		emailAddress: emailAddressRef,
		mobileNumber: mobileNumberRef,
		message: messageRef,
		productInquiry: productInquiry,
	};
	const onFinishFailed = ({ errorFields }) => {
		errorFields.reverse().forEach(({ name }) => {
			const fieldName = name[0];
			const ref = fieldRefs[fieldName];
			if (ref) {
				ref.current.focus();
			}
		});
	};
	const checkboxOpt = [
		{
			label: 'Earthquake Monitoring',
			value: 'Earthquake Monitoring',
		},
		{
			label: 'Video Surveillance Monitoring',
			value: 'Video Surveillance Monitoring',
		},
		{
			label: 'Flood Monitoring',
			value: 'Flood Monitoring ',
		},
		{
			label: 'Weather Monitoring',
			value: 'Weather Monitoring',
		},
		{
			label: 'Emergency Dispatch & Response Monitoring',
			value: 'Emergency Dispatch & Response Monitoring',
		},
	];
	return (
		<div className=" w-full sm:pb-0 pb-5">
			<ConfigProvider
				theme={{
					token: {
						colorBgBase: 'white',
						colorPrimary: ' #1C4E84',
						colorPrimaryBg: 'white',
						// colorPrimaryActive: "white",
						// controlItemBgActive: "white",
					},
				}}
			>
				<Form
					className=" max-w-[700px] col-span-2 row-span-1"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					layout="vertical"
					form={form}
				>
					<Form.Item
						className=""
						name="firstName"
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Please enter First name!',
							},
							{
								validator: (rule, value) => {
									return new Promise((resolve, reject) => {
										if (value && !regExp_Names.test(value)) {
											reject('This is not valid first name!');
										} else {
											resolve();
										}
									});
								},
							},
						]}
					>
						<Input
							size="large"
							className=" w-full py-3"
							placeholder="First name"
							ref={firstNameRef}
						/>
					</Form.Item>
					<Form.Item
						className=""
						name="lastName"
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Please enter Last name!',
							},
							{
								validator: (rule, value) => {
									return new Promise((resolve, reject) => {
										if (value && !regExp_Names.test(value)) {
											reject('This is not valid last name!');
										} else {
											resolve();
										}
									});
								},
							},
						]}
					>
						<Input
							size="large"
							className=" w-full py-3"
							placeholder="Last name"
							ref={lastNameRef}
						/>
					</Form.Item>
					<Form.Item
						className=""
						name="emailAddress"
						hasFeedback
						rules={[
							{
								required: true,

								message: 'Please input E-mail address!',
							},
							{
								type: 'email',
								message: 'This is not valid E-mail address!',
							},
						]}
					>
						<Input
							size="large"
							className=" w-full py-3"
							placeholder="Email"
							ref={emailAddressRef}
						/>
					</Form.Item>
					<Form.Item
						className=""
						name="mobileNumber"
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Please input Mobile number!',
							},
							{
								validator: (rule, value) => {
									return new Promise((resolve, reject) => {
										if (value && !regExp_MobileNumber.test(value)) {
											reject('This is not valid Mobile number!');
										} else {
											resolve();
										}
									});
								},
							},
						]}
					>
						<InputNumber
							size="large"
							addonBefore="+63"
							parser={(value) => value.replace(/\D/g, '')}
							maxLength={10}
							minLength={10}
							min={0}
							controls={false}
							className=" w-full py-1"
							placeholder="Mobile Number ex: 9XX-XXX-XXXX"
							ref={mobileNumberRef}
						/>
					</Form.Item>

					<Form.Item
						className=""
						name="message"
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Please type your message!',
							},
						]}
					>
						<Input.TextArea
							size="large"
							rows={6}
							className=" w-full "
							placeholder="Your message"
							ref={messageRef}
						/>
					</Form.Item>

					<Form.Item
						label={<p className=" text-[20px] m-0 font-semibold ">Product Inquiry</p>}
						className="pt-2 text-[18px] sm:block hidden"
						name="productInquiry"
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Please choose Inquiry type!',
							},
						]}
					>
						<Checkbox.Group
							style={{
								width: '100%',
							}}
							ref={productInquiry}
						>
							<Row>
								{checkboxOpt.map((obj, i) => {
									return (
										<Col key={i} span={8}>
											<Checkbox value={obj.value}>
												<p className=" text-[16px] my-2">{obj.label}</p>
											</Checkbox>
										</Col>
									);
								})}
							</Row>
						</Checkbox.Group>
					</Form.Item>
					<Form.Item
						label={<p className=" text-[20px] m-0 font-semibold">Product Inquiry</p>}
						className="pt-2 text-[18px] sm:hidden block"
						name="productInquiry"
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Please choose Inquiry type!',
							},
						]}
					>
						<Checkbox.Group
							style={{
								width: '100%',
							}}
							ref={productInquiry}
						>
							<Row>
								{checkboxOpt.map((obj, i) => {
									return (
										<Col key={i} span={12}>
											<Checkbox value={obj.value}>
												<p className=" xs:text-[16px] text-[15px] my-2">{obj.label}</p>
											</Checkbox>
										</Col>
									);
								})}
							</Row>
						</Checkbox.Group>
					</Form.Item>

					<Form.Item>
						<Button
							className=" w-full h-12 rounded-lg mt-5 sm:text-lg text-base"
							type="primary"
							htmlType="submit"
							loading={isLoading}
						>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</ConfigProvider>
		</div>
	);
};

export default ContactForm;
