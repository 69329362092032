import { LeftOutlined } from '@ant-design/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const OuterHeader = () => {
	const navigate = useNavigate();

	const goBack = () => {
		navigate(-1);
	};
	// useEffect(() => {
	// 	window.scrollTo(0, 0);
	// }, []);
	return (
		<div className=" fixed top-0 h-16 bg-white/80 backdrop-blur-md w-full z-50">
			<div className=" w-full h-full flex items-center">
				<button
					className=" cursor-pointer text-xl ml-7 bg-transparent border-none hover:text-headerblue"
					onClick={goBack}
				>
					<LeftOutlined /> Back
				</button>
			</div>
		</div>
	);
};

export default OuterHeader;
