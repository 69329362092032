import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import NewsCardFetch from "./News/NewsCardFetch";

const News = () => {
  const textAnimate = {
    offscreen: { y: 150, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 0.7 },
    },
  };
  const cardNewsAnimate = {
    offscreen: { y: 150, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 0.7 },
    },
  };
  return (
    <motion.div
      className=" sm:py-20 py-10 container mx-auto px-4"
      initial={"offscreen"}
      whileInView={"onscreen"}
      viewport={{ once: true, amount: 0.1 }}
      transition={{ staggerChildren: 0.2 }}
    >
      <div className=" flex justify-between items-center pb-10">
        <motion.h1
          className=" text-[#534E4E] md:text-[48px] sm:text-[30px] text-[24px] font-semibold leading-[65px] m-0"
          variants={textAnimate}
        >
          Latest <span className=" text-defblue">News</span>
        </motion.h1>
        <motion.div variants={textAnimate}>
          <Link
            className=" no-underline text-[#545454] hover:text-headerblue md:text-xl text-lg font-medium"
            to="/all-news"
          >
            See all
          </Link>
        </motion.div>
      </div>
      <motion.div variants={cardNewsAnimate}>
        {/* <NewsCard /> */}
        <NewsCardFetch />
      </motion.div>
    </motion.div>
  );
};

export default News;
