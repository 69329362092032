import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Layout } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/slices/auth/authSlice.js';
// import { logo, worldbex } from '../../../../assets/logos';
// import AvatarNav from '../layout/Navbar/AvatarNav';

const { Header } = Layout;

const Navbar = ({ collapsed, setCollapsed }) => {
	const dispatch = useDispatch();

	return (
		<>
			<ConfigProvider
				theme={{
					token: {
						// colorPrimary: 'white',
						// colorPrimaryBg: '#1C4E84',
						// colorBgTextHover: '#df7e1b',
					},
				}}
			>
				<Header
					// className=" sticky-top"
					style={{
						background: '#1C4E84',
						padding: 0,
					}}
				>
					<div className=" flex items-center justify-between px-5 w-auto flex-wrap sm:justify-between gap-x-1 h-full">
						{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
							className: 'trigger scale-150 text-white',
							onClick: () => setCollapsed(!collapsed),
						})}
						<Button
							className=" float-right right-5 top-0 bottom-0"
							onClick={() => {
								dispatch(logout());
								setTimeout(() => {
									window.location.reload();
								}, 300);
							}}
						>
							Logout
						</Button>
					</div>
				</Header>
			</ConfigProvider>
		</>
	);
};

export default Navbar;
