import React from 'react';

const OuterFooter = () => {
	return (
		<div className=" w-full bg-white px-4">
			<h1 className=" sm:text-[16px] text-[14px] text-[#334155] text-center py-10">
				Copyright © 2023 Dynamic Global Soft, Inc. |{' '}
				<span className=" text-headerblue">Term & Condition</span>
			</h1>
		</div>
	);
};

export default OuterFooter;
