export const regExp_MobileNumber = new RegExp(/^[9][0-9]{9}$/);
export const regExp_Names = new RegExp(/^[a-zA-Z\s]+$/);
export const regExp_StudentId = new RegExp(/^\d{4}-\d{2}-\d{6}$/);
export const regExp_Username = new RegExp(/^[a-zA-Z0-9\s]+$/);
export const regExp_Email = new RegExp(/(?=.@+[a-zA-Z])(?=..+[a-zA-Z])/);

// alteast 1 Uppercase
// alteast 1 Lowercase
// alteast 1 Special Chars
// atleast 1 Digit
// atleast 6 Characters
export const regExp_Password = new RegExp(
	/^(?=.[A-Z])(?=.[a-z])(?=.[0-9])(?=.[!@#$%^&)(+=.-])[A-Za-z0-9!@#$%^&)(+=.-]{6,}$/
);
