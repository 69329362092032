import { call, put, takeLatest } from 'redux-saga/effects';
import { reqQuotationApi } from '../../api/reqQuotationApi/reqQuotationApi';
import { request, requestError, requestSuccess } from '../../slices/reqQuotation/reqQuotationSlice';

function* sendRequest({ payload }) {
	try {
		const response = yield call(reqQuotationApi, payload);
		yield put(requestSuccess(response.data));
		if (payload.cb) {
			yield call(payload.cb);
		}
	} catch (error) {
		// Dispatch error action with error message
		yield put(requestError({ message: error.message }));
	}
}

export default function* reqQuotationSaga() {
	yield takeLatest(request.type, sendRequest);
}
