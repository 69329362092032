import { defaultAxios } from '../axios';

export const reqQuotationApi = async (payload) => {
	try {
		const result = await defaultAxios.post(`/api/web/public/sendContactForm`, payload.body);
		return result;
	} catch (err) {
		return err;
	}
};
