import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

const initialState = {
	isLoading: false,
	isAuthenticated: false,
	errorMessage: null,
	token: null,
	userDetails: null,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loggingIn(state) {
			state.isLoading = true;
		},
		loginSuccess(state, { payload }) {
			// state.currentUser = payload.data;
			// state.accountId = payload.accountId;
			state.userDetails = payload.data;
			state.token = payload.token;
			state.isAuthenticated = true;
			state.isLoading = false;
			message.success('Login Success');
		},
		loginError(state, { payload }) {
			state.isAuthenticated = false;
			state.isLoading = false;
			message.warning(payload.message);
		},
		logout(state) {
			// state.currentUser = null;
			// state.accountId = null;
			state.userDetails = null;
			state.isLoading = false;
			state.isAuthenticated = false;
			state.token = null;
			state.errorMessage = null;
			message.warning('Logout Success');
		},
	},
});

export const { loggingIn, logout, loginSuccess, loginError } = authSlice.actions;

export default authSlice.reducer;
