import { motion } from "framer-motion";
import React from "react";
import CarouselSwiper from "./Carousel/CarouselSwiper";

const CitySolutions = () => {
	const textAnimate = {
		offscreen: { y: 150, opacity: 0 },
		onscreen: {
			y: 0,
			opacity: 1,
			transition: { type: "spring", bounce: 0.4, duration: 0.7 },
		},
	};
	const swiperAnimate = {
		offscreen: { y: 150, opacity: 0 },
		onscreen: {
			y: 0,
			opacity: 1,
			transition: { type: "spring", bounce: 0.4, duration: 0.7 },
		},
	};
	return (
		<motion.div
			className=" bg-[#DDDDDD]"
			initial={"offscreen"}
			whileInView={"onscreen"}
			viewport={{ once: true, amount: 0.4 }}
			transition={{ staggerChildren: 0.2 }}
		>
			<div className=" container mx-auto px-4">
				<div className=" sm:py-16 py-12">
					<motion.h1
						className=" font-semibold lg:text-[48px] sm:text-[42px] text-[25px] sm:leading-[65px] text-[#534E4E]"
						variants={textAnimate}
					>
						City and Community
					</motion.h1>
					<motion.h1
						className=" font-semibold lg:text-[48px] sm:text-[42px] text-[25px] sm:leading-[65px] text-defblue uppercase"
						variants={textAnimate}
					>
						Solutions.
					</motion.h1>
					<motion.p
						className=" lg:text-[18px] sm:text-[17px] text-[16px] leading-[27px] text-[#545454] max-w-[676px]"
						variants={textAnimate}
					>
						We enable cities, municipalities, and communities with products designed, developed, and
						maintained by our in-house industry experts as well as through synergies with proven
						product and platform providers.
					</motion.p>
				</div>
				<motion.div variants={swiperAnimate}>
					<CarouselSwiper />
				</motion.div>
			</div>
		</motion.div>
	);
};

export default CitySolutions;
