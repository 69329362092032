import { SnippetsOutlined } from '@ant-design/icons';
import { ConfigProvider, Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { defensyslogo } from '../../assets/logo';

const Sidebar = ({ collapsed, setCollapsed }) => {
	const { Sider } = Layout;
	const location = useLocation();

	const [selectedKey, setSelectedKey] = useState(location.pathname);

	useEffect(() => {
		setSelectedKey(location.pathname.split('/').join(' ').trim().split(' ')[0]);
	}, [location]);

	const getItem = (label, key, icon, children) => {
		return {
			key,
			icon,
			children,
			label,
		};
	};

	const items = [
		// getItem(<Link to="/dashboard">Dashboard</Link>, 'dashboard', <CalendarOutlined />),
		getItem(<Link to="/news">News</Link>, 'news', <SnippetsOutlined />),
	];
	return (
		<div className=" h-screen bg-white">
			<ConfigProvider
				theme={{
					token: {
						colorPrimary: 'white',
						colorPrimaryBg: '#1C4E84',
						// colorBgTextHover: '#F4811F',
					},
				}}
			>
				<Sider
					className=" h-screen relative"
					collapsible
					collapsed={collapsed}
					onCollapse={(value) => setCollapsed(value)}
					breakpoint="lg"
					theme="light"
					width={250}
					collapsedWidth={50}
				>
					<div className="flex flex-col gap-y-5">
						{collapsed || <img className=" w-[124px] mx-auto my-10" src={defensyslogo} alt="" />}
						{collapsed && <img className=" w-[40px] mx-auto my-10 " src={defensyslogo} alt="" />}
						{/* <h1 className=" text-2xl font-bold text-center mt-5">Defensys PH</h1> */}
						<Menu
							// mode="inline"
							items={items}
							defaultSelectedKeys={`${selectedKey}`}
							selectedKeys={[selectedKey]}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								flexDirection: 'column',
							}}
							className=""
						/>
						{/* {collapsed || (
							<div className=" text-center absolute bottom-20 left-0 right-0">
								<p className=" text-[#5E5E5E]">For question and inquiries</p>
								<p>inquire@worldbexevents.com</p>
								<p className=" text-[#5E5E5E]">or contact us at</p>
								<p>8656 9239</p>
							</div>
						)} */}
					</div>
				</Sider>
			</ConfigProvider>
		</div>
	);
};

export default Sidebar;
