import { Routes } from "react-router-dom";

// import Notfound from "../pages/notfound/Notfound";
import visitorRoutes from "./users/visitors";

const Routers = () => {
	return (
		<div className="scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100 w-full">
			<Routes>
				{/*  routes  */}
				{visitorRoutes()}

				{/* <Route path="*" element={<Notfound />} /> */}
			</Routes>
		</div>
	);
};

export default Routers;
