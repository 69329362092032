import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import authReducer, { authSlice } from '../slices/auth/authSlice';
import authReducer, { authSlice } from './auth/authSlice.js';
import newsReducer, { newsSlice } from './news/newsSlice.js';
import reqQuotationReducer from './reqQuotation/reqQuotationSlice';

const authPersistConfig = {
	key: 'authContentWriter',
	version: 1,
	storage,
	whiteList: ['userDetails', 'token'],
};

const rootReducer = combineReducers({
	reqQuotation: reqQuotationReducer,
	[authSlice.name]: persistReducer(authPersistConfig, authReducer),
	[newsSlice.name]: newsReducer,
});

export default rootReducer;
