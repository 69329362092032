import { motion } from "framer-motion";
import React from "react";
import { partner1, partner2, partner3, partner4 } from "../assets/partners";

const ClientsPartners = () => {
  const textAnimate = {
    offscreen: { y: 150, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 0.7 },
    },
  };
  const logoAnimate = {
    offscreen: { y: 150, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 0.7 },
    },
  };
  return (
    <motion.div
      className=" container mx-auto sm:px-0 px-4 sm:py-10 py-0"
      id="partners"
      initial={"offscreen"}
      whileInView={"onscreen"}
      viewport={{ once: true, amount: 0.4 }}
      transition={{ staggerChildren: 0.2 }}
    >
      <motion.h1
        className=" text-center sm:text-[48px] text-[29px] leading-[65px] font-semibold text-[#534E4E] sm:pt-20 sm:pb-20 pt-10 pb-5"
        variants={textAnimate}
      >
        Clients & Partners
      </motion.h1>
      <div className=" flex sm:flex-row flex-col justify-center items-center lg:gap-x-40 gap-x-20 flex-wrap gap-y-5 ">
        <motion.img
          className=""
          src={partner1}
          alt="/"
          variants={logoAnimate}
          whileHover={{ scale: 1.1 }}
        />
        <motion.img
          className=" w-[170px]"
          src={partner2}
          alt="/"
          variants={logoAnimate}
          whileHover={{ scale: 1.1 }}
        />
        <motion.img
          className=""
          src={partner3}
          alt="/"
          variants={logoAnimate}
          whileHover={{ scale: 1.1 }}
        />
        <motion.img
          className=" w-[140px]"
          src={partner4}
          alt="/"
          variants={logoAnimate}
          whileHover={{ scale: 1.1 }}
        />
      </div>
    </motion.div>
  );
};

export default ClientsPartners;
