import { defaultAxios } from '../axios';
// import defaultAxios from 'axios';

export const login = async (body) => {
	try {
		const result = await defaultAxios.post(`/api/web/auth/login`, body);
		return result;
	} catch (err) {
		return err;
	}
};
