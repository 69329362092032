import { MenuOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
// import { items } from "../../constants/static";
import { Button, ConfigProvider } from "antd";
import { Link as RouterLink } from "react-router-dom";
import { defensyslogo, defensyswhite } from "../assets/logo";
import "./Header.css";
import MenuItems from "./Header/MenuItems";

const Header = ({ menuActive, setMenuActive }) => {
  const [is2XlScreen, setIs2XlScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIs2XlScreen(window.innerWidth >= 1536);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // console.log(is2XlScreen);
  const Nav = [
    {
      name: "Home",
      id: "home",
      offset: -80,
    },
    {
      name: "Products",
      id: "products",
      offset: -80,
    },
    {
      name: "About",
      id: "about",
      offset: is2XlScreen ? -30 : -80,
    },
    {
      name: "Clients & Partners",
      id: "partners",
      offset: -30,
    },
    {
      name: "Download",
      id: "download",
      offset: -30,
    },
    {
      name: "News",
      id: "news",
      offset: -30,
    },
    {
      name: "Contact Us",
      id: "contact",
      offset: -30,
    },
  ];

  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeItem, setActiveItem] = useState("home");
  useEffect(() => {
    const handleScroll = () => {
      const home = document.getElementById("home");
      const products = document.getElementById("products");

      if (home.getBoundingClientRect().top <= 80 && products.getBoundingClientRect().top > 120) {
        setActiveItem("home");
      } else if (products?.getBoundingClientRect().top <= 120) {
        setActiveItem("products");
      }
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const shouldBlur = scrollPosition > 0;

  const showMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <>
      <div
        className={
          activeItem !== "home"
            ? `fixed top-0 w-full z-10 ${
                shouldBlur ? "backdrop-blur-md bg-white/70" : "bg-transparent"
              } flex items-center md:justify-around justify-start h-[70px] z-50 `
            : `fixed top-0 w-full z-10 ${
                shouldBlur ? "backdrop-blur-md bg-black/20" : "bg-transparent"
              }  flex items-center md:justify-around justify-start h-[70px] z-50`
        }
      >
        <div>
          <RouterLink to="/" onClick={() => window.reload()}>
            <img
              src={activeItem === "home" ? defensyswhite : defensyslogo}
              alt="Defensys"
              className=" lg:h-[55px] md:h-[45px] h-[40px] md:ml-0 ml-5 cursor-pointer"
            />
          </RouterLink>
        </div>
        <div className=" justify-center items-center xl:gap-x-10 lg:gap-x-7 gap-x-3 md:flex hidden">
          {Nav.map((nav, i) => (
            <Link
              key={i}
              to={nav.id}
              spy={true}
              smooth={true}
              offset={nav.offset}
              duration={700}
              activeClass={activeItem === "home" ? "active-link" : "not-home-active"}
              className={`${
                activeItem === "home" ? "text-white" : "text-headerblue"
              } cursor-pointer py-3 px-1 lg:text-lg lg:text-[17px] text-[15px] text-center `}
            >
              {nav.name}
            </Link>
          ))}
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#1C4E84",
                // colorPrimaryBg: '#1C4E84',
                // colorBgTextHover: '#F4811F',
              },
            }}
          >
            <Button type="primary" onClick={() => window.open("https://digitalgovernmentsolutions.com", "_blank")}>
              Login/Sign Up
            </Button>
          </ConfigProvider>
        </div>
        {/* <div className=" sm:block hidden">
					<Link
						to="contact"
						spy={true}
						smooth={true}
						offset={-30}
						duration={700}
						// activeClass="active-link"
						activeClass={activeItem === "home" ? "active-link" : "not-home-active"}
						// className="text-black py-3 px-1 cursor-pointer"
						className={`${
							activeItem === "home" ? "text-white" : "text-headerblue"
						} cursor-pointer py-3 px-1`}

						// onClick={() => handleLinkClick(nav.name)}
					>
						Contact Us
					</Link>
				</div> */}
        <MenuOutlined
          onClick={showMenu}
          className={
            activeItem !== "home"
              ? "fixed right-6 top-6 scale-150 cursor-pointer text-dgsBlue md:hidden block"
              : "fixed right-6 top-6 scale-150 cursor-pointer text-white md:hidden block"
          }
        />
      </div>
      <MenuItems
        showMenu={showMenu}
        menuActive={menuActive}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        setMenuActive={setMenuActive}
        shouldBlur={shouldBlur}
        Nav={Nav}
        // items={items}
      />
    </>
  );
};

export default Header;
