import { motion } from 'framer-motion';
import React from 'react';
import { imgform } from '../assets/images';
import ContactForm from './Contacts/ContactForm';
import ContactMaps from './Contacts/ContactMaps';

const Contact = () => {
	const textAnimate = {
		offscreen: { y: 150, opacity: 0 },
		onscreen: {
			y: 0,
			opacity: 1,
			transition: { type: 'spring', bounce: 0.4, duration: 0.7 },
		},
	};
	const imgAnimate = {
		offscreen: { y: 150, opacity: 0 },
		onscreen: {
			y: 0,
			opacity: 1,
			transition: { type: 'spring', bounce: 0.3, duration: 0.7 },
		},
	};
	const formAnimate = {
		offscreen: { y: 150, opacity: 0 },
		onscreen: {
			y: 0,
			opacity: 1,
			transition: { type: 'spring', bounce: 0.3, duration: 0.7 },
		},
	};
	return (
		// md:px-20 sm:px-10 px-2
		<motion.div
			id="contact"
			initial={'offscreen'}
			whileInView={'onscreen'}
			viewport={{ once: true, amount: 0.1 }}
			transition={{ staggerChildren: 0.2 }}
		>
			<div className=" container mx-auto grid grid-cols-4 sm:pt-20 pt-10 gap-10 px-4">
				<motion.div className=" lg:col-span-2 col-span-4 lg:block hidden" variants={imgAnimate}>
					<div className=" pt-10 pr-10 ">
						<img className=" w-full lg:h-[854px] h-[400px] rounded-xl" src={imgform} alt="/" />
					</div>
				</motion.div>
				<motion.div
					className=" lg:col-span-2 col-span-4 shadow-2xl sm:px-10 px-5 rounded-xl"
					variants={formAnimate}
				>
					<div>
						<h1 className=" sm:pt-10 pt-5 pb-5 2xl:text-5xl lg:text-3xl md:text-5xl sm:text-4xl text-3xl font-semibold xs:text-start text-center">
							Request for Quotation
						</h1>
					</div>
					<ContactForm />
				</motion.div>
			</div>
			<motion.div
				className=" container mx-auto px-2 md:py-32 sm:py-20 xs:py-10 py-8 sm:mt-20 xs:mt-10 mt-0 flex flex-col sm:gap-y-10 gap-y-4 gap-x-5"
				initial={'offscreen'}
				whileInView={'onscreen'}
				viewport={{ once: true, amount: 0.3 }}
				transition={{ staggerChildren: 0.3 }}
			>
				<div className="">
					<motion.h1
						className=" max-w-[800px] lg:text-5xl md:text-4xl sm:text-3xl text-[22px] font-bold m-0 sm:px-0 px-4 md:leading-[67px] leading-[40px]"
						variants={textAnimate}
					>
						Need more information about <br className=" sm:block hidden"></br>
						<span className=" text-defblue md:leading-[67px] leading-[40px]">Defensys?</span>
					</motion.h1>
				</div>
				<div className=" text-lg flex flex-col gap-y-1">
					<motion.p
						className=" max-w-[550px] m-0 sm:px-0 px-4 sm:text-xl  text-sm text-[#3D3D3D] font-medium"
						variants={textAnimate}
					>
						We will be happy to arrange an appointment with you to review your project.
					</motion.p>
				</div>
				<div className=" grid grid-cols-4 lg:grid-rows-1 sm:grid-rows-2 grid-rows-2">
					<motion.div
						className=" lg:col-span-2 col-span-4 lg:row-span-2 sm:row-span-1 row-span-1 flex justify-start items-center p-4 "
						variants={formAnimate}
					>
						<ContactMaps />
						{/* <div className=" relative">
							<a
								className=" absolute sm:left-5 sm:bottom-10 left-2 bottom-2 sm:px-5 sm:py-3 px-3 py-2 bg-defblue no-underline text-white rounded-xl sm:text-[16px] text-sm"
								href="https://www.google.com/maps/dir/?api=1&destination=Dynamic+Global+Soft+Inc.,+cor+BGC,+Unit+1206+The+Trade+and+Financial+Tower,+32+Ave,+7th+Ave,+Taguig,+Metro+Manila"
								// onClick={() =>
								// 	window.open(
								// 		"https://www.google.com/maps/dir/14.5774714,121.136671/dynamic+global+soft/@14.5693768,121.026984,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3397c8f1578bb23f:0x7c5c367093efd4d7!2m2!1d121.0506058!2d14.5532817"
								// 	)
								// }
								target="_blank"
								rel="noopener noreferrer"
							>
								Get Directions
							</a>
							<iframe
								title="DGSI"
								src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d1930.9012691756013!2d121.0506058!3d14.5532817!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3397c8f1578bb23f%3A0x7c5c367093efd4d7!2sDynamic%20Global%20Soft%20Inc.%20cor%20BGC%20Unit%201206%20The%20Trade%20and%20Financial%20Tower%2C%2032%20Ave%2C%207th%20Ave%20Taguig%2C%20Metro%20Manila!3m2!1d14.5532817!2d121.0506058!5e0!3m2!1sen!2sph!4v1683884236634!5m2!1sen!2sph"
								// width="700"
								// height="450"
								// style={{ border: 0, width: 100%, height: 100% }}
								style={{ border: '0', width: '100%', height: '100%' }}
								allowfullscreen=""
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
							/>
						</div> */}
					</motion.div>
					<motion.div
						className=" lg:col-span-2 col-span-4 lg:row-span-2 sm:row-span-1 row-span-1 2xl:pr-40 xl:pr-16 sm:pr-6 pr-0 sm:px-0 xs:px-4 px-2 flex flex-col xl:gap-y-5 xs:gap-y-2 gap-y-1"
						variants={textAnimate}
					>
						<h1 className=" md:text-4xl sm:text-3xl text-[22px] font-bold xs:my-5 my-3">
							Contact us
						</h1>
						<div>
							<h2 className=" md:text-2xl sm:text-xl text-[17px] font-bold">Main Office</h2>
							<p className=" sm:text-xl  text-sm">
								Unit 1206 12th The Trade & Financial Tower, 7th & 32 Ave. Bonifacio Global City,
								Taguig City, Philippines 1634
							</p>
						</div>
						<div>
							<h2 className=" md:text-2xl sm:text-xl text-[17px] font-bold">Satellite Office</h2>
							<p className=" sm:text-xl  text-sm">
								Blk 1 Lot 5 Ridgemont Avenue, Suburban Drive, Brgy. San Isidro, Taytay, Rizal
							</p>
						</div>
						<div>
							<h2 className=" md:text-2xl sm:text-xl text-[17px] font-bold">Email</h2>
							<p className=" sm:text-xl  text-sm my-1">info@defensys.ph</p>
						</div>
						<div>
							<h2 className=" md:text-2xl sm:text-xl text-[17px] font-bold">Phone</h2>
							<div className=" flex sm:flex-row flex-col xl:text-lg sm:text-[17px]  text-sm 2xl:gap-x-10 xl:gap-x-5 gap-x-2 sm:gap-y-0 gap-y-1">
								<p className=" m-0">0917-987-1468</p>
								<p className=" sm:block hidden">|</p>
								<p className=" m-0">02-82538279</p>
								<p className=" sm:block hidden">|</p>
								<p className=" m-0">0928-524-8720</p>
							</div>
						</div>
					</motion.div>
				</div>
			</motion.div>
		</motion.div>
	);
};

export default Contact;
