import { motion } from "framer-motion";
import React from "react";
import { earthvideo } from "../assets/videos";

const Disaster = () => {
  const textAnimate = {
    offscreen: { y: 0, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 1 },
    },
  };

  const ytAnimate = {
    offscreen: { y: 150, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 1 },
    },
  };
  return (
    <>
      <motion.div
        className=" relative w-full sm:h-full h-[400px] overflow-x-hidden"
        id="about"
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: true, amount: 0.5 }}
        transition={{ staggerChildren: 0.4 }}
      >
        <div className=" w-full h-full absolute z-10 flex flex-col justify-center items-center gap-10 bottom-0 top-0 my-auto sm:p-20 p-0">
          {/* <motion.iframe
            className=" rounded-xl 2xl:w-[1064px] xl:w-[864px] md:w-[664px] sm:w-[464px] w-[300px] 2xl:h-[600px] xl:h-[500px] md:h-[300px] sm:h-[200px] h-[150px]"
            // width="1189"
            // height="669"
            src="https://www.youtube.com/embed/rDxoGzBfJGk"
            title="DGSI Defensys Video Presentation"
            // frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            variants={ytAnimate}
          ></motion.iframe> */}
          <div className=" flex sm:flex-row flex-col justify-center items-center gap-10 w-full">
            <motion.iframe
              className=" rounded-xl 2xl:w-[1064px] xl:w-[864px] md:w-[664px] sm:w-[464px] w-[300px] 2xl:h-[600px] xl:h-[500px] md:h-[300px] sm:h-[200px] h-[150px]"
              // width="1239"
              // height="697"
              src="https://www.youtube.com/embed/SB8nxjkERqg"
              title="Empowering Abra De Ilog with Defensys &amp; Saknungan Citizen App | Dynamic Global Soft Inc"
              // frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              variants={ytAnimate}
            ></motion.iframe>
            <motion.iframe
              className=" rounded-xl 2xl:w-[1064px] xl:w-[864px] md:w-[664px] sm:w-[464px] w-[300px] 2xl:h-[600px] xl:h-[500px] md:h-[300px] sm:h-[200px] h-[150px]"
              // width="1239"
              // height="697"
              src="https://www.youtube.com/embed/w8FaZF3sckc"
              title="Defensys C4RES - a product of Dynamic Global Soft Inc"
              // frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              variants={ytAnimate}
            ></motion.iframe>
          </div>

          {/* <motion.iframe
						className=" rounded-xl 2xl:w-[1064px] xl:w-[864px] md:w-[664px] sm:w-[464px] w-[300px] 2xl:h-[600px] xl:h-[500px] md:h-[300px] sm:h-[200px] h-[150px]"
						// width="1064"
						// height="600"
						src="https://www.youtube.com/watch?v=rDxoGzBfJGk"
						title="DGSI Defensys Video Presentation"
						// frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen
						variants={ytAnimate}
					></motion.iframe> */}
          <motion.h1
            className=" text-white text-[16px] max-w-[1031px] leading-[24px] text-center px-4 sm:block hidden"
            variants={textAnimate}
          >
            For Disaster Risk Reduction Management, trust Defensys to meet all your needs. Defensys is powered by
            Dynamic Global Soft Inc., innovators of digital transformation.
          </motion.h1>
        </div>
        <video
          className=" w-full sm:block hidden"
          src={earthvideo}
          autoPlay={true}
          loop={true}
          muted={true}
          // controls={true}
        />
      </motion.div>
      <motion.div
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.9 }}
        transition={{ staggerChildren: 0.4 }}
      >
        <motion.h1
          className=" text-black text-[14px] max-w-[1031px] leading-[24px] text-center px-4 sm:hidden block"
          variants={textAnimate}
        >
          For Disaster Risk Reduction Management, trust Defensys to meet all your needs. Defensys is powered by Dynamic
          Global Soft Inc., innovators of digital transformation.
        </motion.h1>
      </motion.div>
    </>
  );
};

export default Disaster;
