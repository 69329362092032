import React from 'react';

const Dashboard = () => {
	return (
		<div>
			<h1> THIS IS DASHBOARD!</h1>
		</div>
	);
};

export default Dashboard;
