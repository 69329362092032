import { motion } from "framer-motion";
import React from "react";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { slides } from "../../constants/defensysStatic";

const CarouselSwiper = () => {
	const scaleAnimate = {
		offscreen: { y: 0, opacity: 0.8, scale: 0.85 },
		onscreen: {
			y: 0,
			opacity: 1,
			scale: 1,
			transition: { type: "spring", bounce: 0.2, duration: 0.5 },
		},
	};
	return (
		<div className=" lg:pb-14 pb-8">
			<div className=" sm:block hidden">
				<Swiper
					slidesPerView={2}
					centeredSlides={true}
					loop
					spaceBetween={30}
					autoplay={{
						delay: 3000,
						disableOnInteraction: false,
					}}
					pagination={{
						clickable: true,
					}}
					modules={[Pagination, Autoplay]}
					initialSlide={1}
					// onSlideChange={handleSlideChange}
					style={{
						// "--swiper-navigation-color": "#fff",
						"--swiper-pagination-color": "#1C4E84",
					}}
					className="mySwiper 2xl:w-[80%] w-[100%] bg-[#DDDDDD] rounded-lg"
				>
					{slides.map((slide, i) => {
						return (
							<SwiperSlide
								className=" flex justify-center items-center  relative cursor-grab"
								key={i}
							>
								<motion.div
									className=" w-full h-[100%] rounded-lg py-0"
									initial={"offscreen"}
									whileInView={"onscreen"}
									viewport={{ once: false, amount: 0.5 }}
									transition={{ staggerChildren: 0.1 }}
								>
									<motion.img
										className=" w-full h-full rounded-lg pb-20"
										src={slide.imgSrc}
										alt="/"
										variants={scaleAnimate}
									/>
								</motion.div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
			<div className=" sm:hidden block">
				<Swiper
					slidesPerView={1}
					centeredSlides={true}
					loop
					spaceBetween={30}
					autoplay={{
						delay: 3000,
						disableOnInteraction: false,
					}}
					pagination={{
						clickable: true,
					}}
					modules={[Pagination, Autoplay]}
					initialSlide={1}
					// onSlideChange={handleSlideChange}
					style={{
						// "--swiper-navigation-color": "#fff",
						"--swiper-pagination-color": "#1C4E84",
					}}
					className="mySwiper 2xl:w-[80%] w-[100%] bg-[#DDDDDD] rounded-lg"
				>
					{slides.map((slide, i) => {
						return (
							<SwiperSlide
								className=" flex justify-center items-center  relative cursor-grab"
								key={i}
							>
								<motion.div
									className=" w-full h-[100%] rounded-lg py-0"
									initial={"offscreen"}
									whileInView={"onscreen"}
									viewport={{ once: false, amount: 0.5 }}
									transition={{ staggerChildren: 0.1 }}
								>
									<motion.img
										className=" w-full h-full rounded-lg pb-20"
										src={slide.imgSrc}
										alt="/"
										variants={scaleAnimate}
									/>
								</motion.div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</div>
	);
};

export default CarouselSwiper;
