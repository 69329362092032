import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import OuterFooter from '../../components/OuterFooter';
import OuterHeader from '../../components/OuterHeader';

const OuterLayout = () => {
	const [menuActive, setMenuActive] = useState(false);

	return (
		<div>
			<OuterHeader menuActive={menuActive} setMenuActive={setMenuActive} />
			{/* <div className=" h-[93vh] bg-defensys-bg bg-cover bg-center" id="home"></div> */}
			<Outlet />
			<OuterFooter />
		</div>
	);
};

export default OuterLayout;
