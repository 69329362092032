import React from "react";
import { Link } from "react-scroll";
// import { items } from "../../../constants/static";

import { Button, ConfigProvider } from "antd";
import "./MenuItems.css";

const MenuItems = ({ menuActive, activeItem, shouldBlur, Nav, showMenu, items }) => {
  return (
    <div
      className={
        menuActive
          ? ` backdrop-blur-sm
						 justify-center items-center gap-5 md:hidden flex flex-col fixed top-16 left-0 right-0 px-5 pt-10 pb-5 z-50 ${
               activeItem === "home" ? "bg-black/20" : "bg-white/70"
             }`
          : "hidden"
      }
    >
      {Nav.map((nav, i) => (
        <Link
          key={i}
          to={nav.id}
          spy={true}
          smooth={true}
          offset={nav.offset}
          duration={400}
          // activeClass="active-link"
          activeClass={activeItem === "home" ? "active-link2" : "not-home-active2"}
          // className="text-black py-3 px-1 cursor-pointer"
          // className={menuActive ? `${headerStyle} w-[90%]` : " hidden"}
          className={
            menuActive
              ? `uppercase w-[90%] bg-transparent cursor-pointer px-8 py-2 rounded-lg font-semibold ${
                  activeItem === "home" ? "text-white" : "text-dgsBlue"
                }`
              : "hidden"
          }
          onClick={showMenu}

          // onClick={() => handleLinkClick(nav.name)}
        >
          {nav.name}
        </Link>
      ))}
      <div className=" w-full px-8 flex justify-center pb-5">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#1C4E84",
              // colorPrimaryBg: '#1C4E84',
              // colorBgTextHover: '#F4811F',
            },
          }}
        >
          <Button
            type="primary"
            block
            className=" rounded-lg"
            onClick={() => window.open("https://digitalgovernmentsolutions.com", "_blank")}
          >
            Login/Sign Up
          </Button>
        </ConfigProvider>
      </div>
      {/* <Link
				to="contact"
				spy={true}
				smooth={true}
				offset={-30}
				duration={400}
				// activeClass="active-link"
				activeClass={activeItem === "home" ? "active-link2" : "not-home-active2"}
				// className="text-black py-3 px-1 cursor-pointer"
				// className={menuActive ? `${headerStyle} w-[90%]` : " hidden"}
				className={
					menuActive
						? `uppercase w-[90%] bg-transparent cursor-pointer px-8 py-2 rounded-lg font-semibold ${
								activeItem === "home" ? "text-white" : "text-dgsBlue"
						  }`
						: "hidden"
				}
				onClick={showMenu}

				// onClick={() => handleLinkClick(nav.name)}
			>
				Contact Us
			</Link> */}
    </div>
  );
};

export default MenuItems;
