import { getStore } from '../../../helpers/injectStore';
import { axiosMultipart, defaultAxios } from '../axios';
// import axiosMultipart from 'axios';
// axiosMultipart.interceptors.request((config) => {
// 	config.headers.Authorization = `Bearer `;
// 	return config;
// });
// axiosMultipart.interceptors.request.use(
// 	(config) => {
// 		const token = localStorage.getItem('token');
// 		if (token) {
// 			config.headers['Authorization'] = `Bearer ${token}`;
// 		}
// 		return config;
// 	},
// 	(error) => {
// 		return Promise.reject(error);
// 	}
// );
axiosMultipart.interceptors.request.use(async function (config) {
	const store = getStore();
	config.headers.Authorization = `Bearer ${store.getState().auth.token}`;
	return config;
});
defaultAxios.interceptors.request.use(async function (config) {
	const store = getStore();
	config.headers.Authorization = `Bearer ${store.getState().auth.token}`;
	return config;
});

export const createNews = async ({ body }) => {
	try {
		const result = await axiosMultipart.post(`/api/web/cms/createNews`, body);
		return result;
	} catch (err) {
		return err;
	}
};
export const getNews = async (body) => {
	try {
		const result = await defaultAxios.get(`/api/web/cms/fetchNews`, body);
		return result;
	} catch (err) {
		return err;
	}
};
export const getNewsPublic = async (body) => {
	try {
		const result = await defaultAxios.get(`/api/web/public/fetchNews`, body);
		return result;
	} catch (err) {
		return err;
	}
};

export const removeNews = async ({ params }) => {
	try {
		const result = await defaultAxios.get(`/api/web/cms/deleteNews/` + params);
		return result;
	} catch (err) {
		return err;
	}
};

export const updateNews = async ({ params, body }) => {
	try {
		const result = await axiosMultipart.post(`/api/web/cms/editNews/` + params, body);
		return result;
	} catch (err) {
		return err;
	}
};

export const removeContent = async ({ params, body }) => {
	try {
		const result = await defaultAxios.get(`/api/web/cms/deleteContent/` + params);
		return result;
	} catch (err) {
		return err;
	}
};
export const addContent = async ({ params, body }) => {
	try {
		const result = await axiosMultipart.post(`/api/web/cms/addContent`, body);
		return result;
	} catch (err) {
		return err;
	}
};

export const editContent = async ({ params, body }) => {
	try {
		const result = await axiosMultipart.post(`/api/web/cms/editContent`, body);
		return result;
	} catch (err) {
		return err;
	}
};
