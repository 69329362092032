import React from 'react';
import { facebook } from '../assets/icons';
import { DGSIwhite } from '../assets/logo';

const Footer = () => {
	return (
		<div className=" w-full max-w-full bg-[#33689C] text-white">
			<div className=" sm:hidden block px-0 w-full xs:pt-10 pt-5">
				<div className=" flex items-center justify-around flex-wrap">
					<img className=" sm:max-w-[180px] max-w-[100px]" src={DGSIwhite} alt="/" />
					{/* <img className=" sm:max-w-[200px] max-w-[120px] " src={dgswhite} alt="/" /> */}
				</div>
			</div>
			<div className=" grid sm:grid-cols-7 grid-cols-4 sm:px-0 px-4">
				<div className=" sm:col-span-3 col-span-2 h-full sm:block hidden">
					<div className="  xl:px-20 px-10 py-16 gap-10 flex-wrap">
						<div className="flex justify-start items-center max-w-[420px] mx-auto">
							<img className=" sm:max-w-[180px] max-w-[100px]" src={DGSIwhite} alt="/" />
						</div>
						{/* <img className=" h-8" src={dgswhite} alt="/" /> */}
					</div>
					<div className=" w-full justify-center items-center sm:flex hidden">
						<div className=" xl:px-20 px-10 max-w-[600px] md:pb-14 pb-4 ">
							<p className=" font-extralight md:text-[16px] text-sm md:leading-6 leading-7">
								Dynamic Global Soft Inc. has acclaimed a turn-key IT implementers and systems
								integrators handling a long line of services that includes the procurement of IT
								hardware and software, consultancy services systems integration and full-scale
								project management.
							</p>
						</div>
					</div>
				</div>
				<div className=" sm:col-span-1 col-span-2 flex flex-col sm:gap-y-10 gap-y-5">
					<h1 className=" sm:mt-16 mt-5 sm:text-2xl text-[16px] font-light flex sm:items-start sm:justify-start justify-center items-center xl:h-0 h-20">
						DGSI Products
					</h1>
					<ul className=" sm:list-disc list-none font-extralight flex flex-col xs:gap-y-10 gap-y-3 sm:pl-10 pl-0 sm:items-start sm:justify-start justify-center items-center sm:text-start text-center xs:text-[16px] text-sm">
						<li>Internet of Things</li>
						<li className=" ">Event Management System</li>
						<li>Mobile Point of Sales</li>
					</ul>
				</div>
				<div className=" col-span-2 grid sm:grid-cols-4 grid-cols-2 sm:ml-16 ml-0  sm:text-start text-center">
					<div className=" sm:col-span-4 col-span-2 flex sm:justify-start justify-center sm:items-start items-center">
						<h1 className=" sm:mt-16 mt-5 sm:text-2xl text-[16px] font-light  sm:block flex  items-center xl:h-0 h-20">
							Tags
						</h1>
					</div>
					<div className=" col-span-2 row-span-2 sm:py-5 py-0">
						<ul className=" list-none font-extralight flex flex-col xs:gap-y-10 gap-y-3 sm:pl-10 pl-0 xs:text-[16px] text-sm">
							<li>Application</li>
							{/* <li className=" lg:block hidden"></li> */}
							<li>Gallery</li>
							<li>Website</li>
							<li className=" lg:hidden block">Event</li>
							<li className=" lg:hidden block">Mobile</li>
							<li className=" lg:hidden block">Privacy policy</li>
						</ul>
					</div>
					<div className=" col-span-2 row-span-2 py-5 lg:block hidden xs:text-[16px] text-sm">
						<ul className=" list-none font-extralight flex flex-col gap-y-10 pl-10">
							<li>Event</li>
							{/* <li className=" lg:block hidden"></li> */}
							<li>Mobile</li>
							<li>Privacy policy</li>
						</ul>
					</div>
				</div>
				<div className=" col-span-1 relative sm:block hidden">
					<img className=" absolute bottom-24 left-10 " src={facebook} alt="/" />{' '}
				</div>
				<div className=" col-span-7 relative">
					<div className="  sm:hidden block">
						<img className=" absolute bottom-2 left-0 right-0 mx-auto" src={facebook} alt="/" />
					</div>
					<hr className=" sm:mx-20 mx-4"></hr>

					<p className=" text-[#D0D0D0] py-10 flex justify-center items-center font-extralight sm:text-sm text-[12px] text-center xs:px-10 px-5">
						Copyright © 2023 Dynamic Global Soft, Inc. | Term & Condition
					</p>
				</div>
			</div>
		</div>
	);
};

export default Footer;
