import React from "react";

const Home = () => {
  return (
    <div id="home">
      <div className=" h-[93vh] bg-defensys-bg3 bg-cover bg-center relative flex justify-center items-center">
        <div className=" bg-black/50 backdrop-blur-[2px] absolute inset-0"></div>
        <div className=" text-white drop-shadow-2xl xs:pt-10 pt-20 max-w-[1000px]">
          <div className=" flex flex-col items-center gap-y-4 flex-wrap text-center">
            <h1 className=" md:text-[67px] sm:text-[47px] text-4xl sm:mx-0 mx-2 drop-shadow-2xl font-bold text-center sm:leading-[4.5rem] leading-[3rem] shadow-inner">
              Defensys
            </h1>
            {/* <h1 className=" md:text-[67px] sm:text-[47px] text-4xl sm:mx-0 mx-2 drop-shadow-2xl font-bold text-center">
						Government Solutions
					</h1> */}
            {/* <p className=" md:text-3xl sm:text-2xl text-xl sm:mx-0 mx-2 font-medium drop-shadow-2xl my-0"></p> */}
          </div>
          <div className="text-center md:text-2xl sm:text-xl text-xl sm:mx-0 mx-2 sm:mt-14 mt-7 drop-shadow-2xl">
            <h4>
              A Disaster Risk Reduction and Management (DRRM) platform that centrally monitors flood, earthquake, CCTV
              or drone, weather, and emergencies, into one command and control center.
            </h4>
            {/* <p>
							A Disaster Risk Reduction Management (DRRM) platform that centrally monitors weather,
							earthquakes, emergencies, and video surveillance.
						</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
