import {
  card1,
  card2,
  card3,
  card4,
  card5,
  cctv1,
  cctv2,
  cctvlogo,
  earthq1,
  earthq2,
  earthquakelogo,
  emer1,
  emer2,
  emergencylogo,
  floodlogo,
  floodm1,
  floodm2,
  img1,
  img2,
  img3,
  img4,
  img5,
  weatherlogo,
  weatherm1,
  weatherm2,
} from "../assets/images";
import { joinasiabot1, joinasiabot2, joinasiamain, news1 } from "../assets/news";

export const cardImage = [
  {
    id: 1,
    alt: "CCTV Monitoring",
    alt2: "CCTV",
    imgSrc: `${card1}`,
    titleTop: "Video Surveillance",
    titleBot: "Monitoring",
    logo: `${cctvlogo}`,
    cardContent: [
      {
        title: "Video Surveillance Monitoring",
        sub: "CCTV Monitoring",
        details:
          "A unified platform to view the remote or local CCTV footages from the central command center. The remote CCTV footages are viewable via internet connection.",
        list: ["Filter Views", "Selection of a specific CCTV Channel", "Carousel of CCTV Footages"],
        sub2: "Drone Monitoring",
        details2:
          "Aerial surveillance of situations or incidents viewable to the command center video walls or executive mobile apps.",
        img1: `${cctv1}`,
        img2: `${cctv2}`,
      },
    ],
  },
  {
    id: 2,
    alt: "Flood Monitoring",
    alt2: "Flood",
    imgSrc: `${card2}`,
    titleTop: "Flood",
    titleBot: "Monitoring",
    logo: `${floodlogo}`,
    cardContent: [
      {
        title: "Flood Monitoring",
        sub: "Flood Monitoring Features:",
        // details:
        // 	'The flood monitoring system features flood sensor, rain gauge, water level monitoring, risk analysis, multi-level alert status, and text / email blaster.',
        list: [
          "Flood Sensor",
          "Water Level Monitoring",
          "Rain Gauge",
          "Risk Analysis",
          "Text / Email Blaster",
          "Multi-Level Alert Status",
        ],
        img1: `${floodm1}`,
        img2: `${floodm2}`,
      },
    ],
  },
  {
    id: 3,
    alt: "Earthquake",
    alt2: "Earthquake",
    imgSrc: `${card3}`,
    titleTop: "Earthquake",
    titleBot: "Monitoring",
    logo: `${earthquakelogo}`,
    cardContent: [
      {
        title: "Earthquake Monitoring",
        sub: "Earthquake Recording Instrument (ERI)",
        details:
          "An advanced Earthquake Recording Instrument (ERI) with Structural Health Monitoring System (SHMS) that remotely monitors and analyzes the structural integrity of buildings. The system is fully compliant with DPWH Guidelines and rules on earthquake recording instrumentation for buildings and the National Structural Code of the Philippines (NSCP) DPWH MC-001-2015 IRR. This system allows for early detection of structural damage and helps to ensure the safety of buildings in the event of an earthquake.",
        list: ["Maps of ERI devices", "Latest warning", "Average intensity for the last 24 hours"],
        img1: `${earthq1}`,
        img2: `${earthq2}`,
      },
    ],
  },
  {
    id: 4,
    alt: "Weather Monitoring",
    alt2: "Weather",
    imgSrc: `${card4}`,
    titleTop: "Weather",
    titleBot: "Monitoring",
    logo: `${weatherlogo}`,
    cardContent: [
      {
        title: "Weather Monitoring",
        sub: "Disaster Visibility Information System",
        details:
          "The Disaster Visibility Information System (DVIS) is an advanced monitoring system that provides visibility and control of hyperlocal weather information. The system has a pre-disaster risk assessment report, early warning system with alert notifications through various touchpoints, and localized weather monitoring. It is continuously monitored 24/7 on all locations even without weather stations and can be monitored via web or mobile. This system allows emergency responders and the public to stay informed about potential weather-related hazards and take appropriate action to protect themselves and their communities.",
        list: [
          "Air Quality",
          "Wind Direction",
          "Dew Point",
          "Tropical Cyclone",
          "Heat Index",
          "Humidity",
          "Air Pressure",
        ],
        img1: `${weatherm1}`,
        img2: `${weatherm2}`,
      },
    ],
  },
  {
    id: 5,
    alt: "Emergency Response Monitoring",
    alt2: "Emergency Response",
    imgSrc: `${card5}`,
    titleTop: "Emergency Dispatch &",
    titleBot: "Response Monitoring",
    logo: `${emergencylogo}`,
    cardContent: [
      {
        title: "Emergency Dispatch & Response Monitoring",
        sub: "Emergency and Non-emergency reporting",
        details:
          "This system provides a platform for emergency and non-emergency reporting through a mobile application for citizens and government response units, and a web application for agents, LGU departments, Dashboard and reporting. The system allows for quick and efficient communication between citizens and emergency responders, providing real-time information about emergencies and allowing for rapid response.",
        list: [
          "Incident Reported",
          "Incident Resolved",
          "Type of Incidents",
          "With/Without Injury",
          "Incident per LGU & Barangay",
          "Responder Mobile App",
          "Citizens App",
        ],
        img1: `${emer1}`,
        img2: `${emer2}`,
      },
    ],
  },
];

export const newsCard = [
  {
    id: 1,
    imgSrc: `${news1}`,
    title: "DGSI joins Adapt",
    sub: "Asia 2022",
    date: "November 11, 2022",
    details: "DGSI showcases Integrated Command and Control System during the Adapt...",
    path: "dgsi-joins-adapt-asia-2022",
    newsPage: {
      imgMain: `${joinasiamain}`,
      title: "DGSI joins Adapt Asia 2022",
      by: "Admin",
      date: "November 11, 2022",
      images: [`${joinasiabot1}`, `${joinasiabot2}`, `${joinasiabot2}`],
      img1: `${joinasiabot1}`,
      img2: `${joinasiabot2}`,
      titleInfo:
        "DGSI showcases Integrated Command and Control System during the Adapt Asia 2022 held at The Fifth, Rockwell, Makati City, November 10-11, 2022.",
      detailedInfo:
        "The Dynamic Global Soft, Inc. (DGSI) has been one of the gold sponsors during the recent Adapt Asia 2022, held last November 10 & 11, 2022 at The Fifth, Rockwell, Makati City. The event was attended by different sectors, mostly the Disaster Risk Reduction and Management Officers (DRRMOs) of different LGUs nationwide, and was graced by international resource speakers on climate adaptation.",
    },
  },
  // {
  // 	id: 2,
  // 	imgSrc: `${img2}`,
  // 	title: 'DGSI joins Adapt',
  // 	sub: 'Asia 2023',
  // 	date: 'November 11, 2023',
  // 	details: 'DGSI showcases Integrated Command and Control System during the Adapt...',
  // 	path: 'dgsi-joins-adapt-asia-2023',
  // 	newsPage: {
  // 		imgMain: `${joinasiamain}`,
  // 		title: 'DGSI joins Adapt Asia 2023',
  // 		by: 'Admin',
  // 		date: 'November 11, 2023',
  // 		images: [`${joinasiabot1}`, `${joinasiabot2}`, `${joinasiabot1}`, `${joinasiabot2}`],
  // 		img1: `${joinasiabot1}`,
  // 		img2: `${joinasiabot2}`,
  // 		titleInfo:
  // 			'DGSI showcases Integrated Command and Control System during the Adapt Asia 2022 held at The Fifth, Rockwell, Makati City, November 10-11, 2022.',
  // 		detailedInfo:
  // 			'The Dynamic Global Soft, Inc. (DGSI) has been one of the gold sponsors during the recent Adapt Asia 2022, held last November 10 & 11, 2022 at The Fifth, Rockwell, Makati City. The event was attended by different sectors, mostly the Disaster Risk Reduction and Management Officers (DRRMOs) of different LGUs nationwide, and was graced by international resource speakers on climate adaptation. The weather monitoring',
  // 	},
  // },
  // {
  // 	id: 3,
  // 	imgSrc: `${joinasiamain}`,
  // 	title: 'DGSI joins Adapt',
  // 	sub: 'Asia 2024',
  // 	date: 'November 11, 2024',
  // 	details: 'DGSI showcases Integrated Command and Control System during the Adapt...',
  // 	path: 'dgsi-joins-adapt-asia-2024',
  // 	newsPage: {
  // 		imgMain: `${joinasiamain}`,
  // 		title: 'DGSI joins Adapt Asia 2024',
  // 		by: 'Admin',
  // 		date: 'November 11, 2024',
  // 		images: [
  // 			`${img4}`,
  // 			`${joinasiabot2}`,
  // 			`${joinasiabot1}`,
  // 			`${joinasiabot2}`,
  // 			`${joinasiabot2}`,
  // 		],
  // 		img1: `${joinasiabot1}`,
  // 		img2: `${joinasiabot2}`,
  // 		titleInfo:
  // 			'DGSI showcases Integrated Command and Control System during the Adapt Asia 2022 held at The Fifth, Rockwell, Makati City, November 10-11, 2022.',
  // 		detailedInfo:
  // 			'The Dynamic Global Soft, Inc. (DGSI) has been one of the gold sponsors during the recent Adapt Asia 2022, held last November 10 & 11, 2022 at The Fifth, Rockwell, Makati City. The event was attended by different sectors, mostly the Disaster Risk Reduction and Management Officers (DRRMOs) of different LGUs nationwide, and was graced by international resource speakers on climate adaptation. The weather monitoring',
  // 	},
  // },
  // {
  // 	id: 4,
  // 	imgSrc: `${img5}`,
  // 	title: 'DGSI joins Adapt',
  // 	sub: 'Asia 2025',
  // 	date: 'November 11, 2025',
  // 	details: 'DGSI showcases Integrated Command and Control System during the Adapt...',
  // 	path: 'dgsi-joins-adapt-asia-2025',
  // 	newsPage: {
  // 		imgMain: `${joinasiamain}`,
  // 		title: 'DGSI joins Adapt Asia 2025',
  // 		by: 'Admin',
  // 		date: 'November 11, 2025',
  // 		images: [
  // 			`${img4}`,
  // 			`${img1}`,
  // 			`${img2}`,
  // 			`${img3}`,
  // 			`${joinasiabot1}`,
  // 			`${joinasiabot2}`,
  // 			`${joinasiabot2}`,
  // 		],
  // 		img1: `${joinasiabot1}`,
  // 		img2: `${joinasiabot2}`,
  // 		titleInfo:
  // 			'DGSI showcases Integrated Command and Control System during the Adapt Asia 2022 held at The Fifth, Rockwell, Makati City, November 10-11, 2022.',
  // 		detailedInfo:
  // 			'The Dynamic Global Soft, Inc. (DGSI) has been one of the gold sponsors during the recent Adapt Asia 2022, held last November 10 & 11, 2022 at The Fifth, Rockwell, Makati City. The event was attended by different sectors, mostly the Disaster Risk Reduction and Management Officers (DRRMOs) of different LGUs nationwide, and was graced by international resource speakers on climate adaptation. The weather monitoring',
  // 	},
  // },
  // {
  // 	id: 5,
  // 	imgSrc: `${news1}`,
  // 	title: 'DGSI joins Adapt',
  // 	sub: 'Asia 2022',
  // 	date: 'November 11, 2022',
  // 	details: 'DGSI showcases Integrated Command and Control System during the Adapt...',
  // 	path: 'dgsi-joins-adapt-asia-2022',
  // 	newsPage: {
  // 		imgMain: `${joinasiamain}`,
  // 		title: 'DGSI joins Adapt Asia 2022',
  // 		by: 'Admin',
  // 		date: 'November 11, 2022',
  // 		images: [`${joinasiabot1}`, `${joinasiabot2}`],
  // 		img1: `${joinasiabot1}`,
  // 		img2: `${joinasiabot2}`,
  // 		titleInfo:
  // 			'DGSI showcases Integrated Command and Control System during the Adapt Asia 2022 held at The Fifth, Rockwell, Makati City, November 10-11, 2022.',
  // 		detailedInfo:
  // 			'The Dynamic Global Soft, Inc. (DGSI) has been one of the gold sponsors during the recent Adapt Asia 2022, held last November 10 & 11, 2022 at The Fifth, Rockwell, Makati City. The event was attended by different sectors, mostly the Disaster Risk Reduction and Management Officers (DRRMOs) of different LGUs nationwide, and was graced by international resource speakers on climate adaptation. The weather monitoring',
  // 	},
  // },
  // {
  // 	id: 6,
  // 	imgSrc: `${news1}`,
  // 	title: 'DGSI joins Adapt',
  // 	sub: 'Asia 2022',
  // 	date: 'November 11, 2022',
  // 	details: 'DGSI showcases Integrated Command and Control System during the Adapt...',
  // 	path: 'dgsi-joins-adapt-asia-2022',
  // 	newsPage: {
  // 		imgMain: `${joinasiamain}`,
  // 		title: 'DGSI joins Adapt Asia 2022',
  // 		by: 'Admin',
  // 		date: 'November 11, 2022',
  // 		images: [`${joinasiabot1}`, `${joinasiabot2}`],
  // 		img1: `${joinasiabot1}`,
  // 		img2: `${joinasiabot2}`,
  // 		titleInfo:
  // 			'DGSI showcases Integrated Command and Control System during the Adapt Asia 2022 held at The Fifth, Rockwell, Makati City, November 10-11, 2022.',
  // 		detailedInfo:
  // 			'The Dynamic Global Soft, Inc. (DGSI) has been one of the gold sponsors during the recent Adapt Asia 2022, held last November 10 & 11, 2022 at The Fifth, Rockwell, Makati City. The event was attended by different sectors, mostly the Disaster Risk Reduction and Management Officers (DRRMOs) of different LGUs nationwide, and was graced by international resource speakers on climate adaptation. The weather monitoring',
  // 	},
  // },
];

export const slides = [
  {
    imgSrc: `${img1}`,
  },
  {
    imgSrc: `${img2}`,
  },
  {
    imgSrc: `${img3}`,
  },
  {
    imgSrc: `${img4}`,
    alt: "Disaster Solutions",
  },
  {
    imgSrc: `${img5}`,
    alt: "Command Center",
  },
];

export const generateNews = [
  {
    imgMain: ``,
    title: "",
    img1: ``,
    img2: ``,
    titleInfo: "",
    detailedInfo: "",
  },
];
