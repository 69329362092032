import React from "react";
import CitySolutions from "../components/CitySolutions";
import ClientsPartners from "../components/ClientsPartners";
import Contact from "../components/Contact";
import Disaster from "../components/Disaster";
import DownloadBrochure from "../components/DownloadBrochure";
import ExploreMore from "../components/ExploreMore";
import Footer from "../components/Footer";
import Home from "../components/Home";
import News from "../components/News";
import Products from "../components/Products";

const Defensys = () => {
  console.log("ITO AY HOMEPAGE");
  return (
    <div>
      <Home />
      <ExploreMore />
      <Products />
      <Disaster />
      <ClientsPartners />
      <DownloadBrochure />
      <div id="news">
        <News />
        <CitySolutions />
      </div>
      <Contact />
      <Footer />
    </div>
  );
};

export default Defensys;
