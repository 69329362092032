import axios from 'axios';

export const defaultAxios = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	},
});

export const axiosMultipart = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	headers: {
		'content-type': 'multipart/form-data',
		'Access-Control-Allow-Origin': '*',
	},
});

export const token = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	},
});
