import React from "react";
import { Navigate, Outlet, Route, useLocation } from "react-router-dom";
import Dashboard from "../../pages/ContentWriter/home/Dashboard";
import News from "../../pages/ContentWriter/home/News";
import NewsGen from "../../pages/ContentWriter/home/NewsGen";
import NewsTest from "../../pages/ContentWriter/home/NewsTest";
import PageLayout from "../../pages/ContentWriter/home/PageLayout";
import Defensys from "../../pages/Defensys";
import Login from "../../pages/login/Login";
import AllGeneratedNews from "../../pages/news/AllGeneratedNews";
import NewsGenerated from "../../pages/news/NewsGenerated";
import NewsGenerator from "../../pages/news/NewsGenerator";
import DefensysLayout from "../../pages/visitors/DefensysLayout";
import OuterLayout from "../../pages/visitors/OuterLayout";

const getisAuth = () => {
  const storage = JSON.parse(localStorage.getItem("persist:authContentWriter"));
  return storage?.userDetails ? JSON.parse(storage.userDetails) : null;
};

const Auth = () => {
  const auth = getisAuth();
  const location = useLocation();
  if (auth) {
    // console.log('LOCATION', location);
    // if (location.pathname === '/login') {
    // 	return <Navigate to="/all-news" state={{ from: location }} replace />;
    // } else {
    // }
    return <Outlet />;
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};

const UnAuth = () => {
  const auth = getisAuth();
  const location = useLocation();
  return auth ? <Navigate to="/news" state={{ from: location }} replace /> : <Outlet />;
};

const visitorRoutes = () => {
  return (
    <>
      {/* <Route path="/" element={<DefensysLayout />} /> */}
      <Route path="*" element={<Navigate to="/" />} />

      <Route element={<DefensysLayout />}>
        <Route path="/" element={<Defensys />} />
        {/* <Route path="/all-news" element={<AllNews />} />
				<Route path="/dgsi-joins-adapt-asia-2022" element={<DgsiJoinsAdaptAsia2022 />} /> */}
      </Route>

      <Route element={<OuterLayout />}>
        <Route path="/fnews/:id" element={<NewsGenerator />} />
        <Route path="/news/:id" element={<NewsGenerated />} />
        {/* <Route path="/news" element={<NewsCardFetch />} /> */}
        <Route path="/all-news" element={<AllGeneratedNews />} />
      </Route>

      <Route element={<UnAuth />}>
        <Route path="/login" element={<Login />} />

        {/* <Route element={<OuterLayout />}>
					<Route path="/news/:id" element={<NewsGenerator />} />
					<Route path="/login" element={<Login />} /> */}
        {/* <Route path="/" element={<Defensys />} /> */}
        {/* <Route path="/all-news" element={<AllNews />} /> */}
        {/* <Route path="/dgsi-joins-adapt-asia-2022" element={<DgsiJoinsAdaptAsia2022 />} /> */}
        {/* </Route> */}
      </Route>

      <Route element={<Auth />}>
        {/* <Route path="/all-news" element={<AllNews />} /> */}
        <Route element={<PageLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/add-news" element={<NewsGen />} />
          <Route path="/newstest" element={<NewsTest />} />
        </Route>
      </Route>
      {/* <Route path="/login" element={<Login />} /> */}
      {/* <Route path="/home" element={<RootLayout />} />
			<Route path="/grid" element={<GridLayout />} />
			<Route path="/t" element={<PaginationK />} /> */}
    </>
  );
};

export default visitorRoutes;
