import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

const initialState = {
	isLoading: false,
	reqDetails: [],
	errorMessage: '',
};

export const reqQuotation = createSlice({
	name: 'reqQuotation',
	initialState,
	reducers: {
		request(state) {
			state.isLoading = true;
		},
		requestSuccess(state, { payload }) {
			state.isLoading = false;
			state.reqDetails = payload;
			message.success(payload.message);
		},
		requestError(state, { payload }) {
			state.isLoading = false;
			message.warning(payload.message);
		},
	},
});

export const { request, requestSuccess, requestError } = reqQuotation.actions;

export default reqQuotation.reducer;
