import { configureStore } from '@reduxjs/toolkit';
// import { persistStore } from 'redux-persist';
import rootReducer from './slices/rootReducer';

//SAGA
import createSagaMiddleware from 'redux-saga';
import authSaga from './saga/auth/authSaga';
import reqQuotationSaga from './saga/reqQuotation/reqQuotationSaga';
// import loginSaga from './saga/sagas/auth/loginSaga';

import { persistStore } from 'redux-persist';
import newsSaga from './saga/news/newsSaga';

const saga = createSagaMiddleware();

const store = configureStore({
	reducer: rootReducer,
	// 	middleware: [saga],
	// });
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false,
		}).concat(saga),
});

//RUN SAGA
// saga.run(loginSaga);
saga.run(reqQuotationSaga);
saga.run(authSaga);
saga.run(newsSaga);

// export const persistor = persistStore(store);
export const persistor = persistStore(store);
export default store;
