import { Button, Radio } from 'antd';
import React, { useRef, useState } from 'react';
import { addNewsType } from '../../../constants/static';

const NewsTest = () => {
	// const [textStyle, setTextStyle] = useState('');
	const [text, setText] = useState('');
	const [size, setSize] = useState('');
	const [weight, setWeight] = useState('');
	const [align, setAlign] = useState('');
	const [height, setHeight] = useState('');
	const [preview, setPreview] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	// const [form] = Form.useForm();
	const formRef = useRef(null);

	const previewWithId = preview.map((item, index) => {
		return {
			...item,
			id: index + 1,
		};
	});

	// useEffect(() => {
	// 	console.log('asdasdasd ', preview);
	// }, [preview]);

	// console.log(text);
	// console.log(textStyle);
	const handleDeleteClick = (index) => {
		// Perform the logic for deleting an object from the preview state based on its index
		const newPreview = [...preview];
		newPreview.splice(index, 1);
		setPreview(newPreview);
	};

	const handleEditClick = (p) => {
		// form.setFieldsValue({ ...p });
	};

	// console.log('this is preview', preview);
	// console.log('this is preview with id', previewWithId);
	// console.log(selectedOption);

	return (
		<div className=" w-full flex flex-col items-center container mx-auto">
			<h1 className=" text-3xl font-semibold">Preview</h1>
			<div className=" w-full bg-white shadow-md py-5 px-5 overflow-hidden">
				{preview.map((p, i) => {
					if (p.type === 'text') {
						return (
							<div className="" key={i}>
								<h1 className={`${p.fontWeight} ${p.fontSize} ${p.textAlign} ${p.textHeight}`}>
									{p.textArea}
								</h1>
								{/* <div className=" hover:block hidden"> */}
								<Button className="" onClick={() => handleDeleteClick(i)}>
									Delete
								</Button>
								<Button className="" onClick={() => handleEditClick(p)}>
									Edit
								</Button>
								{/* </div> */}
							</div>
						);
					} else {
						return null;
					}
				})}
				{/* <h1 className={`${size} ${height} ${weight} ${align}`}>{text}</h1> */}
			</div>
			<Radio.Group
				className=" flex justify-center items-center"
				style={{
					width: '100%',
				}}
				onChange={(e) => setSelectedOption(e.target.value)}
				// ref={fontSize}
				// onChange={onSizeChange}
			>
				{/* <Row className=" w-full flex justify-center"> */}
				{addNewsType.map((obj, i) => {
					return (
						// <Col key={i} span={8}>
						<Radio key={i} value={obj.value}>
							<p className=" xs:text-[16px] text-[15px] my-2">{obj.label}</p>
						</Radio>
						// </Col>
					);
				})}
				{/* </Row> */}
			</Radio.Group>
			{/* {selectedOption === 'text' && (
				<AddTextForm preview={preview} setPreview={setPreview} form={form} formRef={formRef} />
			)} */}
			<div className=" flex justify-center gap-x-5">
				<button>Add text</button>
				<button>Add image</button>
				<button>Add video</button>
			</div>
		</div>
	);
};

export default NewsTest;
