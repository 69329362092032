import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { newsCard } from '../../constants/defensysStatic';

const NewsGenerator = () => {
	const { id } = useParams();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	// const newsWithId = newsCard.map((item, index) => ({
	// 	...newsCard,
	// 	id: index + 1,
	// }));
	const news = newsCard.find((c) => c.id === parseInt(id));
	// const news = newsCard.find((c) => c.title === title);
	const newsPage = news.newsPage;
	// console.log(news.title);
	const textAnimate = {
		offscreen: { y: 150, opacity: 0 },
		onscreen: {
			y: 0,
			opacity: 1,
			transition: { type: 'spring', bounce: 0.4, duration: 1 },
		},
	};
	const imgAnimate = {
		offscreen: { y: 150, opacity: 0 },
		onscreen: {
			y: 0,
			opacity: 1,
			transition: { type: 'spring', bounce: 0.3, duration: 1 },
		},
	};
	return (
		<div className=" md:px-0 px-6">
			<motion.div
				className="mt-16 flex flex-col items-center justify-center bg-[#eeeeee] py-10"
				initial={'offscreen'}
				whileInView={'onscreen'}
				viewport={{ once: true, amount: 0.1 }}
				transition={{ staggerChildren: 0.2 }}
			>
				<motion.div className=" flex justify-center items-center" variants={imgAnimate}>
					<img
						className=" w-full h-full rounded-md"
						src={newsPage.imgMain}
						alt="DGSI-Joins-Adapat-Asia-2022"
					/>
				</motion.div>
				<div className=" px-5">
					<motion.h1
						className=" text-[#1e293b] font-bold text-center mt-10 sm:text-[32px] text-[24px]"
						variants={textAnimate}
					>
						{newsPage.title}
					</motion.h1>
					<motion.p
						className=" text-defblue text-center sm:text-[16px] text-[14px]"
						variants={textAnimate}
					>
						By {newsPage.by} / {newsPage.date} / FEATURED
					</motion.p>
				</div>
			</motion.div>
			<motion.div
				className=" bg-[#F8F9F9] sm:py-32 py-10"
				initial={'offscreen'}
				whileInView={'onscreen'}
				viewport={{ once: true, amount: 0.1 }}
				transition={{ staggerChildren: 0.3 }}
			>
				<div className=" container mx-auto grid grid-cols-4 sm:gap-10 gap-5 bg-white shadow-xl sm:px-20 px-2 lg:py-40 md:py-28 py-10 rounded-lg">
					{newsPage.images.map((img, i) => {
						const isCenterImage =
							// (newsPage.images.length === 3 && i === 2) ||
							// (newsPage.images.length === 5 && i === 4);
							i === newsPage.images.length - 1 && newsPage.images.length % 2 !== 0;
						const gridItemClasses = `${
							isCenterImage
								? 'lg:col-start-2 lg:col-end-4 col-start-1 col-end-5 justify-self-center'
								: 'lg:col-span-2 col-span-4'
						} `;
						return (
							<motion.div
								key={i}
								// className={` lg:col-span-2 col-span-4 `}
								className={gridItemClasses}
								variants={imgAnimate}
							>
								<img className=" w-full h-full rounded-md" src={img} alt="/" />
							</motion.div>
						);
					})}
					{/* <div className=" lg:col-span-2 col-span-4">
						<img className=" w-full h-full" src={newsPage.img1} alt="/" />
					</div>
					<div className=" lg:col-span-2 col-span-4">
						<img className=" w-full h-full" src={newsPage.img2} alt="/" />
					</div> */}
					<motion.h1
						className=" col-span-4 text-center sm:text-xl text-lg text-[#313553] pt-6 px-4"
						variants={textAnimate}
					>
						{newsPage.titleInfo}
					</motion.h1>
					{/* <h1 className=" col-span-4 text-center sm:text-lg text-base text-[#313553]">
						November 10-11, 2022.
					</h1> */}
					<motion.p
						className=" col-span-4 text-justify sm:text-lg text-base text-[#313553] "
						variants={textAnimate}
					>
						{newsPage.detailedInfo}
					</motion.p>
				</div>
			</motion.div>
		</div>
	);
};

export default NewsGenerator;
