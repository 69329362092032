import { GoogleMap, InfoWindow, MarkerF } from '@react-google-maps/api';
import React, { useState } from 'react';

const ContactMaps = () => {
	// const [map, setMap] = useState(null);

	// const onLoad = useCallback(function callback(map) {
	// 	setMap(map);
	// }, []);

	// const onUnmount = useCallback(function callback(map) {
	// 	setMap(null);
	// }, []);
	const [clicked, setClicked] = useState(true);
	const defaultProps = {
		center: {
			lat: 14.553992,
			lng: 121.050606,
		},

		zoom: 17,
	};
	return (
		<GoogleMap
			// onClick={(e) =>
			// 	setLocation({
			// 		coords: { latitude: e.latLng.lat(), longitude: e.latLng.lng() },
			// 	})
			// }
			mapContainerStyle={{ height: '100%', width: '100%' }}
			center={defaultProps.center}
			zoom={defaultProps.zoom}
			options={{ disableDefaultUI: true }}

			// onLoad={onLoad}
			// onUnmount={onUnmount}
		>
			<div className=" relative">
				<a
					className=" absolute sm:left-5 sm:top-5 left-2 top-2 sm:px-5 sm:py-3 px-3 py-2 bg-defblue shadow-lg no-underline text-white rounded-xl sm:text-[16px] text-sm"
					href="https://www.google.com/maps/dir/?api=1&destination=Dynamic+Global+Soft+Inc.,+cor+BGC,+Unit+1206+The+Trade+and+Financial+Tower,+32+Ave,+7th+Ave,+Taguig,+Metro+Manila"
					// onClick={() =>
					// 	window.open(
					// 		"https://www.google.com/maps/dir/14.5774714,121.136671/dynamic+global+soft/@14.5693768,121.026984,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3397c8f1578bb23f:0x7c5c367093efd4d7!2m2!1d121.050606058!2d14.5532817"
					// 	)
					// }
					target="_blank"
					rel="noopener noreferrer"
				>
					Get Directions
				</a>
			</div>
			<MarkerF
				position={{
					lat: 14.553282,
					lng: 121.050606,
				}}
				onClick={() => setClicked(!clicked)}
			/>
			{clicked && (
				<InfoWindow
					position={{
						lat: 14.553282,
						lng: 121.050606,
					}}
					options={{
						pixelOffset: new window.google.maps.Size(0, -30),
						closeBoxURL: '',
					}}
					onCloseClick={() => setClicked(!clicked)}
				>
					<div className=" max-w-[200px]">
						<h3 className=" font-semibold">Dynamic Global Soft Inc.</h3>
						<p>
							cor BGC Unit 1206 The Trade and Financial Tower, 32 Ave 7th Ave Taguig, Metro Manila,
							Philippines
						</p>
						<a
							className=" no-underline"
							href="https://www.google.com/maps?ll=14.55328,121.050606&z=22&t=m&hl=en-US&gl=US&mapclient=apiv3&cid=8961097215706649815"
							target="_blank"
							rel="noopener noreferrer"
						>
							View on Google Maps
						</a>
					</div>
				</InfoWindow>
			)}
		</GoogleMap>
	);
};

export default ContactMaps;
