import { motion } from "framer-motion";
import React from "react";
import { DefensysBrochure } from "../assets/pdf";

const DownloadBrochure = () => {
  const textAnimate = {
    offscreen: { y: 150, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 0.7 },
    },
  };
  //   const handleDownload = () => {
  //     // Replace 'your-pdf-file.pdf' with the actual filename of your PDF file.
  //     const fileUrl = "localhost:3000/defensys_brochure.pdf";
  //     const link = document.createElement("a");
  //     link.href = fileUrl;
  //     link.setAttribute("download", "defensys_brochure.pdf");
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   };
  return (
    <motion.div
      className=" sm:pt-20 pt-10 sm:pb-10 pb-0"
      id="download"
      initial={"offscreen"}
      whileInView={"onscreen"}
      viewport={{ once: true, amount: 0.4 }}
      transition={{ staggerChildren: 0.2 }}
    >
      <div className=" w-full h-[454px] bg-download-bg bg-cover bg-center ">
        <div className=" w-full h-full flex flex-col justify-center items-center gap-y-8 mx-auto container sm:px-0 px-4">
          <motion.h1
            className=" md:text-[48px] sm:text-[35px] text-[28px] md:leading-[65px] leading-[45px] font-medium text-white text-center"
            variants={textAnimate}
          >
            Download Our Defensys Brochure
          </motion.h1>
          {/* <motion.p
						className=" max-w-[960px] md:text-[20px] sm:text-[19px] text-[18px] leading-[27px] text-center text-white"
						variants={textAnimate}
					>
						Discover the power of Disaster Risk Reduction Management: learn how to minimize the
						impact of disasters on your community and prepare for emergencies.
					</motion.p> */}

          <motion.a
            // href={`${process.env.REACT_APP_BROCHURE_URL}`}
            // href="https://defensys.ph/defensys_brochure.pdf"
            // href="https://defensys.ph/static/media/Defensys_Brochure.pdf"
            href={DefensysBrochure}
            // download="Defensys_brochure"
            target="_blank"
            rel="noreferrer"
            // onClick={handleDownload}
            variants={textAnimate}
          >
            <button className=" uppercase text-white font-semibold md:text-[20px] sm:text-[19px] text-[18px] py-4 sm:px-14 px-7 bg-transparent cursor-pointer border-white hover:bg-headerblue">
              Download Brochure
            </button>
          </motion.a>
        </div>
      </div>
    </motion.div>
  );
};

export default DownloadBrochure;
