import { UploadOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, DatePicker, Form, Input, Spin, Upload } from 'antd';
import moment from 'moment';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

const AddHeadline = ({
	setMainData,
	mainData,
	setFile,
	setImagePreview,
	handleAddContent,
	form1,
	setIsOpenAddNews,
}) => {
	// const [form] = Form.useForm();
	const { isLoading } = useSelector((state) => state.news);

	const headline = useRef(null);
	const subtitle = useRef(null);
	const fieldRefs = {
		headline: headline,
		subtitle: subtitle,
	};
	const onFinish = (values) => {
		// console.log(values.coverURL.fileList[0].originFileObj);

		// const formattedDate = moment(values.subtitle).format('YYYY-MM-DD');
		const convertDate = values.subtitle.toDate();
		const formattedDate = moment(convertDate).format('YYYY-MM-DD HH:mm:ss');

		const newValues = {
			...values,
			coverURL: values.coverURL.fileList[0].originFileObj,
			subtitle: formattedDate,
		};

		setMainData(newValues);
	};
	const onFinishFailed = ({ errorFields }) => {
		errorFields.reverse().forEach(({ name }) => {
			const fieldName = name[0];
			const ref = fieldRefs[fieldName];
			if (ref) {
				ref.current.focus();
			}
		});
	};
	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = () => resolve(reader.result);

			reader.onerror = (error) => reject(error);
		});

	const props = {
		name: 'fileList',
		accept: 'image/png, image/jpeg',
		multiple: false,
		beforeUpload: async (file) => {
			setFile(file);
			setImagePreview(await getBase64(file));
			return false;
		},
	};

	// const dateObj = new Date(dateString);
	// const formatter = new Intl.DateTimeFormat('en-US', {
	// 	month: 'long',
	// 	day: 'numeric',
	// 	year: 'numeric',
	// });
	// const formattedDate = formatter?.format(dateObj) ?? null;

	const onChangeValues = (changedValues, allValues) => {
		// console.log('allvalues', allValues);
		if (changedValues.coverURL) {
			const file = changedValues.coverURL.fileList[0].originFileObj;
			setMainData({ ...mainData, coverURL: file });
		} else if (changedValues.subtitle) {
			const convertDate = changedValues.subtitle.toDate();
			const formattedDate = moment(convertDate).format('YYYY-MM-DD HH:mm:ss');
			setMainData({ ...mainData, subtitle: formattedDate });
		}

		// else if (changedValues.subtitle) {
		// 	const dateObj = new Date(changedValues.subtitle);
		// 	const formatter = new Intl.DateTimeFormat('en-US', {
		// 		month: 'long',
		// 		day: 'numeric',
		// 		year: 'numeric',
		// 	});
		// 	const formattedDate = formatter?.format(dateObj) ?? null;
		// 	setMainData({ ...mainData, subtitle: formattedDate });
		// }
		else {
			const { subtitle, ...restValues } = allValues;
			setMainData({ ...mainData, ...restValues });
		}
		// else {
		// 	setMainData(allValues);
		// }
	};

	// console.log('the main data', mainData);
	const handleSubmit = () => {
		if (mainData.coverURL && mainData.headline && mainData.subtitle) {
			setIsOpenAddNews(true);
			// console.log('may laman lahat');
		}
	};

	const { TextArea } = Input;

	return (
		<div className=" mt-10">
			<Spin spinning={isLoading}>
				<Form
					name="headlineForm"
					className=" max-w-[700px] mx-auto bg-white px-10 pt-5 pb-10 shadow-lg rounded-md grid grid-cols-4 relative"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					layout="vertical"
					form={form1}
					onValuesChange={onChangeValues}
					// disabled={isLoading}
				>
					<Form.Item
						label={
							<p className=" md:text-[20px] text-[18px] pt-10 font-semibold m-0">Headline Text</p>
						}
						className="pt-2 text-[18px] col-span-4"
						name="headline"
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Please input headline text!',
							},
						]}
					>
						<TextArea
							ref={headline}
							// onChange={onTextChange}
							rows={2}
						/>
						{/* <Input size='middle'/> */}
					</Form.Item>
					<Form.Item
						label={<p className=" md:text-[20px] text-[18px] m-0 font-semibold">Date</p>}
						className="pt-2 text-[18px] sm:col-span-2 col-span-4"
						name="subtitle"
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Please select date!',
							},
						]}
					>
						{/* <TextArea
						ref={subtitle}
						// onChange={onTextChange}
						rows={2}
					/> */}
						<DatePicker
							size="middle"
							disabledDate={(e) => {
								const now = new Date();
								return e.$d.valueOf() > now.valueOf();
							}}
						/>
					</Form.Item>
					<Form.Item
						label={<p className=" md:text-[20px] text-[18px] m-0 font-semibold">Cover image</p>}
						name="coverURL"
						className="pt-2 sm:col-span-2 col-span-4"
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Please upload cover image',
							},
						]}
					>
						<Upload
							// action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
							// listType="picture"
							// accept="image/png, image/jpeg"
							{...props}
							maxCount={1}
							multiple={false}
						>
							<Button icon={<UploadOutlined />}>Upload Cover Image</Button>
						</Upload>
					</Form.Item>
					{/* {Object.keys(mainData).length === 0 && (
					<Form.Item>
						<Button
							className=" w-full h-12 rounded-lg mt-5 sm:text-lg text-base"
							type="primary"
							htmlType="submit"
						>
							Add Headline
						</Button>
					</Form.Item>
				)}
				{Object.keys(mainData).length !== 0 && (
					<Form.Item>
						<Button
							className=" w-full h-12 rounded-lg mt-5 sm:text-lg text-base"
							type="primary"
							htmlType="submit"
						>
							Edit Headline
						</Button>
					</Form.Item>
				)} */}
					<div className=" col-span-4 flex justify-center items-center pb-5 pt-10">
						<ConfigProvider
							theme={{
								token: {
									colorPrimary: '#808080',
								},
							}}
						>
							<Button
								type="primary"
								onClick={handleAddContent}
								// className=" absolute right-5 bottom-5"
								className=" col-span-4 max-w-[400px]"
								size="large"
								block
							>
								Add Content
							</Button>
						</ConfigProvider>
					</div>
					<div className=" col-span-4 flex justify-center items-center pb-5 pt-2 ">
						<Button
							htmlType="submit"
							size="large"
							type="primary"
							block
							// className=" sm:absolute bottom-5 left-5 "
							className=" col-span-4 max-w-[150px]"
							// onClick={() => setIsOpenAddNews(true)}
							onClick={handleSubmit}
						>
							Publish News
						</Button>
					</div>
				</Form>
			</Spin>
		</div>
	);
};

export default AddHeadline;
